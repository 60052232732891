@import 'styles/scss/variable.scss';
.Header {
  padding: toRem(16) toRem(16);
  .searchHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .subTitle {
      font-size: 24px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: #161616;
    }
    .toolbar {
      display: flex;
      gap: 8px;
      align-items: center;
      .inviteButton {
        cursor: pointer;
      }
      .search {
        width: toRem(36);
        height: toRem(36);
        border-radius: toRem(100);
        background-color: #f4f4f4;
        display: flex;
        justify-content: center;
        align-items: center;
        :global(.InputDelayWrapper) {
          width: toRem(433);
        }
      }
    }
  }
  .membershipTab {
    margin-top: 24px;
    :global(.MuiButtonBase-root) {
      font-size: 14px !important;
    }
  }
}