@import 'styles/scss/variable.scss';
@import 'styles/scss/fontSizeByRem.scss';

.DaysSinceLastWorkoutCard {
  :global(.CardSimpleWrapper) {
    padding: toRem(16) toRem(6) toRem(16) toRem(16);
    box-sizing: border-box;
    height: toRem(112);
    display: flex;
    box-shadow: toRem(2) toRem(2) toRem(24) rgba(147, 147, 147, 0.2);
    border-radius: toRem(16);
  }

  .weekStreak {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: toRem(16);
    .achieve {
      width: toRem(65);
      height: toRem(65);
      position: relative;
      border-radius: toRem(200);
      svg {
        width: 100%;
        height: 100%;
      }
    }

    .rightContent {
      width: 100%;
      .text {
        font-family: 'GRIFTER';
        font-weight: 700;
        font-size: toRem(18);
        line-height: toRem(28);
      }

      .bestAchieve {
        font-family: 'MonoSpec';
        font-weight: 500;
        font-size: toRem(12);
        line-height: toRem(20);
        color: $SecondaryText;
        letter-spacing: -0.05em;
      }
    }
  }

  @media screen and (max-width: 600px) {
    :global(.CardSimpleWrapper) {
      padding: toRem(16) toRem(16);
      box-sizing: border-box;
      height: toRem(112);
      display: flex;
      box-shadow: toRem(2) toRem(2) toRem(24) rgba(147, 147, 147, 0.2);
      border-radius: toRem(16);
    }
  
    .weekStreak {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: toRem(16);
      .achieve {
        width: toRem(65);
        height: toRem(65);
        position: relative;
        border-radius: toRem(200);
        svg {
          width: toRem(65);
          height: toRem(65);
        }
      }
  
      .rightContent {
        width: 100%;
        .text {
          font-family: 'GRIFTER';
          font-weight: 700;
          font-size: toRem(18);
          line-height: toRem(28);
        }
  
        .bestAchieve {
          font-family: 'MonoSpec';
          font-weight: 500;
          font-size: toRem(14);
          line-height: toRem(20);
          color: $SecondaryText;
          letter-spacing: -0.05em;
        }
      }
    }
  }
}