.form-request-success {
    max-width: 800px;
    margin: 24px auto;
    background-color: #fff;
    border-radius: 16px;
    padding: 20px 0;
    &-content {
        color: #161616;
        text-align: center;
        &__title {
            font-weight: 700;
            font-family: "GRIFTER";
            font-size: 24px;
            margin-bottom: 30px;
        }
        &__description {
            font-weight: 400;
            font-family: "Work Sans";
            font-size: 16px;
            margin-bottom: 40px;
        }
        .form-request_button {
            text-align: center;
            margin: 0 auto;
            width: 60%;
        }
    }
}

@media screen and (min-width: 768px) {
    .form-request-success {
        padding: 20px 60px;
        &-content {
            .form-request_button {
                width: 40%;
            }
        }
    }
}
