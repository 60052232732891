// colors
$BodyColor: #F3F4F7;
$color-white: #ffffff;
$ContentColor: $color-white;
$SidebarBackgroundColor: #353535;
$BoxShadowDialog: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
$ActiveItem: #FFEDE8;
$BlackColor: #000000;
$BlackColor2: #161616;
$GrayColor: #939393;
$GrayColor1: #D1D1D1;
$GrayColor2: #f4f4f4;
$GrayColor3: #e6e6e6;

$DarkPrimaryColor: #2f56d3;
$PrimaryColor: #5B86E5;
$LightPrimaryColor: #96ADFF;
$TextIconsColor: #FFFFFF;
$AccentColor: #36D1DC;
$PrimaryText: $BlackColor2;
$SecondaryText: #4f4f4f;
$BlackText: $BlackColor2;
$BlackText1: $BlackColor2;
$DividerColor: #BDBDBD;
$ElementColor: #f2f2f2;

$SuccessColor: #00932F;
$WarningColor: #F0AD4E;
$DangerColor: #EB3B5B;
$InfoColor: #5BC0DE;
$BlueLightColor: #36D1DC;
$HoverWhiteColor: #F9F8F8; // when hover an element has background color is white
$BorderHoverColor: $BlackColor2;
$BorderColor: #D1D1D1;
$BorderActiveColor: $BlackColor2;
$F8F8F8: rgba(189,189,189,0.10);
$NotificationColor: #C7261B;
$BackgroundCollapseBtn: #5C5C5C;
$TableHoverColor:#F5F5F5;

$color-primary-red: #FF4106;
$color-primary-black: #3a3a3a;
$color-gray:$GrayColor;
$color-gray-light: #dcdcdc;
$color-gray-lightest: #f6f6f6;

// text font size
$Display4FontSize: 112px;
$Display3FontSize: 56px;
$Display2FontSize: 45px;
$Display1FontSize: 34px;
$HeadlineFontSize: 24px;
$TitleFontSize: 20px;
$HeaderFontSize: 18px;
$SubheaderFontSize: 16px;
$BodyMenuFontSize: 14px;
$BodyFontSize: 14px;
$CaptionFontSize: 12px;
$ButtonFontSize: 14px;
//font-family, default is roboto-regular
%RobotoLight {
    font-family: Roboto;
    font-weight: 300;
}
%RobotoMedium {
    font-family: Roboto;
    font-weight: 600;
}
%RobotoRegular {
    font-family: Roboto;
    font-weight: normal;
}
%RobotoBold {
    font-family: Roboto;
    font-weight: bold;
}

//text font
%Display4Font {

    @extend %RobotoLight;
    font-size: $Display4FontSize;
    letter-spacing: -0.22px;
}
%Display3Font {
    font-size: $Display3FontSize;
    letter-spacing: -0.11px;
}
%Display2Font {
    font-size: $Display2FontSize;
    letter-spacing: -0.09px;
}
%Display1Font {
    font-size: $Display1FontSize;
    letter-spacing: -0.07px;
}
%HeadlineFont {
    font-size: $HeadlineFontSize;
    letter-spacing: -0.05px;
}
%TitleFont {
    @extend %RobotoMedium;
    font-size: $TitleFontSize;
    letter-spacing: -0.04px;
}
%SubheaderFont {
    font-size: $SubheaderFontSize;
    letter-spacing: -0.03px;
}
%BodyMenuFont {
    @extend %RobotoMedium;
    font-size: $BodyMenuFontSize;
    letter-spacing: -0.03px;
}
%BodyFont {
    font-size: $BodyFontSize;
    letter-spacing: -0.03px;
}
%CaptionFont {
    font-size: $CaptionFontSize;
    letter-spacing: -0.02px;
}
%ButtonFont {
    @extend %RobotoMedium;
    font-size: $ButtonFontSize;
    letter-spacing: -0.03px;
}
//text color
.primary-color{
   color: $PrimaryColor;
}
.success-color {
    color: $SuccessColor;
}
.danger-color{
   color: $DangerColor;
}
.secondarytext-color {
    color: $SecondaryText;
}
//text size
.caption-size {
    font-size: $CaptionFontSize;
    letter-spacing: -0.02px;
}

//size
$sideBarWidth: 220px;
$headerHeight: 64px;
$listDataWidth: 250px;

//background color for tooltip
$BackgroundColorHoveringContainer: #F2FAFD;

$MinHeight: calc(100vh - 175px);

$screen-sm-workout-max: 1250px;
$zIndexMax: 1600;
$zIndexLoading: 1686;
@import './fontSizeByRem.scss';