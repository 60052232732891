.MembershipInviteList {
  min-height: 600px;
  .titleWrapper{
    .title {
      font-size: 28px;
      font-weight: 300;
      line-height: 32.84px;
      text-align: left;
      color: #161616
    }
    .subTitle {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: #808080;

    }
  }
  .machineCardWrapper {
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .emptyMachine {
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #808080;
    margin-top: 10px;
  }
  @media screen and (max-width: 900px) {
    .MembershipWrapper {
      padding: 24px 12px;
    }
  }
  @media screen and (max-width: 500px) {
    @function toVw($px-px) {
      @return ($px-px * 100vw) / 390px;
    }
    .emptyMachine {
      font-size: toVw(16px);
      margin: 0 toVw(-6px) !important;
    }
  }
}