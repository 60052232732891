.workout-history-layout {
    animation-name: animateLoadPage;
    animation-duration: 2s;
    -webkit-overflow-scrolling: touch;
    @keyframes animateLoadPage {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    padding: 0 1.714rem;
    display: grid;
    gap: 1.714rem;
    grid-template-columns: 17.2857rem auto;
    box-sizing: border-box;
    .leftMainContent {
        width: 17.2857rem;
        box-shadow: 2px 2px 24px rgba(147, 147, 147, 0.2);
        border-radius: 1.14rem;
        background-color: $ContentColor;
        -webkit-overflow-scrolling: touch;
    }
    .rightMainContent {
        -webkit-overflow-scrolling: touch;
    }
    .chart-and-history-container {
        margin-top: 64px;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 400px;
    }
    .icon-with-label {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            margin-right: 10px;
            width: 26px;
            height: 26px;
        }
    }
    @media screen and (max-width: $screen-sm-workout-max) {
        padding: 0;
        display: block;
        .leftMainContent {
            width: 100vw;
        }
        .rightMainContent {
            width: 100vw;
        }
    }
}
