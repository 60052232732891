@import "styles/scss/variable.scss";
.headerWrapper {
    display: flex;
    max-width: 100vw;
    gap: toRem(20);
    .headerAppbar {
        background-color: $color-white;
        @media screen and (max-width: 1250px) {
            background-color: $BlackColor;
        }
    }
    .leftHeaderWrapper {
        flex: 1 1 85%;
        display: flex;
        align-items: center;
        gap: toRem(47);
        ::-webkit-scrollbar {
            display: none;
        }
        :global(#aviron-logo) {
            flex: 1 1 toRem(215);
            @media screen and (max-width: $screen-sm-workout-max) {
                flex: 1 1 toRem(108);
                justify-content: center;
                display: flex;
                a {
                    display: block;
                    width: fit-content;
                }
                img {
                    height: toRem(20);
                    width: toRem(108);
                }
            }
        }

        :global(.sidebarDesktopWrapper) {
            flex: 1 1 80%;
        }
    }

    :global(.avartarRight) {
        flex: 1 1 toRem(219);
        @media screen and (max-width: $screen-sm-workout-max) {
            flex: 1 1 toRem(48);
            position: relative;
            right: toRem(-30);
            :global(.text-name-avatar) {
                display: none;
            }
            :global(.icon-down) {
                display: none;
            }
            :global(.avatarButtonWrapper) {
            }
            &:global(.MuiButtonBase-root) {
                width: fit-content;
                align-items: flex-start;
                -webkit-align-items: flex-start;
                -webkit-box-align: flex-start;
                justify-content: flex-start;
                -webkit-justify-content: flex-start;
                -webkit-box-align: start;
                padding: 0;
            }
        }
    }
}

.device {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &-group {
        font-weight: bold;
        text-transform: uppercase;
        p {
            margin: 0 10px;
            color: #808080;
            font-family: "Work Sans";
            font-size: 12px;
        }
        &__end {
            color: #161616 !important;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 96px;
            font-family: "GRIFTER";
            font-size: 14px;
        }
    }
}
