@import 'styles/scss/fontSizeByRem.scss';
.WorkoutVerticalChart {
  @media screen and (min-width: 960px) { 
    height: 100%;
    box-sizing: border-box;
  }
  :global(.CardSimpleWrapper) {
    @media screen and (min-width: 960px) { 
      height: 100%;
      box-sizing: border-box;
    }
    @media screen and (max-width: 600px) {
      padding: 0;
      width: 100vw;
      .titleCardSimple {
        padding: 0 toRem(16);
        margin-bottom: toRem(-10);
      }
    }
    :global(#VerticalBarChart) {
      height: toRem(350);
    }
  }
  @media screen and (max-width: 600px) {
    margin-top: toRem(-16);
    margin-bottom: toRem(16);
    :global(.chartYTitle) {
      margin-left: toRem(16);
    }
  }
}