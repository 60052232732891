@import 'styles/scss/variable.scss';
.modalCustom {
  display: flex;
  align-items: center;
  justify-content: center;
}

.change-password-modal {
  background-color: white;
  border-radius: 16px;
  width: 568px;
  position: relative;
  &:focus-visible {
      outline: 0;
  }

  :global(.SelectFormFieldWrapper) {
    fieldset {
        border-radius: 6px !important;
    }
  }

  @media screen and (max-width: 768px) {
      width: 100%;
      margin: 0 20px;
  }

  .close-btn {
      position: absolute;
      top: 18px;
      right: 18px;
      cursor: pointer;
  }

  .text-field-group {
      padding: 10px 32px;
  }

  .titleModal {
      padding: 48px 32px 8px 32px;
      font-weight: bold;
      font-size: 24px;
      color: #161616;
      font-family: GRIFTER;
      text-transform: uppercase;
  }

  .desc {
    padding: 10px 24px 10px 32px;
    font-family: Work Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;

  }

  .buttons-group {
      padding: 24px 32px;
      display: flex;
      justify-content: right;
      gap: 24px;

      :global(.MuiButton-root) {
          padding: 12px;
          height: 52px;
          border-radius: 100px;
          width: toRem(160);
          font-size: 16px;
      }
  }

  @media screen and (max-width: 768px) {
      .text-field-group {
          padding: 10px 36px;
      }

      .buttons-group {
          padding: 24px 36px;
      }
  }
}