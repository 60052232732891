@import 'styles/scss/fontSizeByRem.scss';
.UserCard {
  display: flex;
  gap: toRem(12);
  align-items: center;
  .avatar {
    width: toRem(40);
    height: toRem(40);
    border-radius: toRem(100);
    :global(.MuiAvatar-root) {
      width: 100%;
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: toRem(100);
    }
  }
  .textBlock {
    .username {

    }
    .email {
      color: #808080;
    }
  }
}