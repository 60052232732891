@import 'styles/scss/variable.scss';
@import 'styles/scss/fontSizeByRem.scss';
.OlympianCardWrapper {
  :global(.CardSimpleWrapper) {
    padding: toRem(16) toRem(8) toRem(16) toRem(16);
    box-sizing: border-box;
    height: toRem(112);
    display: flex;
    box-shadow: toRem(2) toRem(2) toRem(24) rgba(147, 147, 147, 0.2);
    border-radius: toRem(16);
  }
  .weekStreak {
    display: flex;
    align-items: center;
    gap: toRem(16);
    width: 100%;
    .achieve {
      width: toRem(65);
      height: toRem(65);
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .circleProcess {
        position: absolute;
        width: toRem(65) !important;
        height: toRem(65) !important;
        z-index: 1;
        svg {
          color: $GrayColor2;
          width: 100%;
          height: 100%;
          circle {
            stroke-width: toRem(4);
          }
        }
      }
      :global(.circleProcessFakeBar) {
          // animation-name: rotate !important;
          animation-duration: 3s !important;
          animation-direction: reverse !important;
        svg {
          circle {
            stroke: url(#circleBarRadient);
            stroke-linecap: round;
          }
        }
      }
      img {
        width: toRem(45);
        height: toRem(45);
        object-fit: contain;
        z-index: 0;
      }
    }
    .rightContent {
      .text {
        font-family: 'GRIFTER';
        font-weight: 700;
        font-size: toRem(18);
        line-height: toRem(28);
        text-transform: uppercase;
        width: 100%;
      }
      .bestAchieve {
        font-family: 'MonoSpec';
        font-weight: 500;
        font-size: toRem(14);
        line-height: toRem(20);
        color: $SecondaryText;
        margin-top: toRem(12);
        text-transform: uppercase;
        letter-spacing: -0.5px;
      }
    }
  }
}