@function toVw($px-px) {
  @return ($px-px * 100vw) / 390px;
}
.MembershipCardMobile {
  background-color: white;
  .header {
    margin-bottom: toVw(24px);
  }
  .membershipTab {
    margin-bottom: toVw(24px);
  }
  .membershipTable {
    table {
      th {
        &:nth-child(3) {
          display: none;
        }
        &:nth-child(1) {
          width: toVw(40px);
        }
        &:nth-child(5) {
          :global(.deleteFunc) {
            right: toVw(-3px);
          }
        }
      }
      tr {
        td {
          border-radius: none;
          :global(.StatusWrapper) {
            position: relative;
            left: toVw(15px);
          }
        }
        :global(.userCell) {
          width: toVw(217px);
          :global(.UserCardWrapper) {
            :global(.email),:global(.username) {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: toVw(162px);
            }
          }
        }
        :global(.roleCell) {
          width: toVw(50px);
        }
        :global(.tdActions) {
          display: none;
        }
      }
      :global(.trHeader){
        th {
          padding-bottom: 0;
        }
      }
    }
  }
}