.membership-canceled-modal {
    outline: none;
    background-color: white;
    z-index: 2;
    border-radius: 16px;
    width: 640px;

    padding: 48px 32px;
    position: relative;
    margin-top: -62px;
    .close-button {
        position: absolute;
        right: 20px;
        top: 10px;
    }
    .membership-canceled-modal-image {
        text-align: center;
        img {
            width: 160px;
            height: 160px;
            object-fit: cover;
            margin-bottom: 56px;
        }
    }
}

@media screen and (max-width: 768px) {
    .membership-canceled-modal {
        margin: -62px 22px 0px;
        
        padding: 32px 24px;

        .membership-canceled-modal-image {
            img {
                width: 120px;
                height: 120px;
                object-fit: cover;
                margin-bottom: 32px;
            }
        }
    }
}
