.register-beta-modal-generate {
    width: 568px;
    border-radius: 5px;
    background-color: white;
    position: relative;
    padding: 20px 0 48px;
    height: fit-content;
    margin: 24px auto;
    border-radius: 16px;
    &__close {
        text-align: right;
        button {
            cursor: pointer;
            border-width: 0px;
            background-color: transparent;
            padding-right: 20px;
        }
    }
}
