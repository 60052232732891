@import 'styles/scss/variable.scss';

.Checkbox {
  display: flex;
  align-items: center;
  :global(.Mui-checked) {
    svg {
      fill: #FF4106;
    }
  }
  :global(.Mui-checked.Mui-disabled) {
    svg {
      fill: #F9926F;
    }
  }
  :global(.MuiCheckbox-root) {
    &:hover {
      background-color: transparent;
    }
    svg {
      width: toRem(25);
      height: toRem(25);
    }
  }
}