.register-beta-modal-cancel {
    width: 568px;
    border-radius: 5px;
    background-color: white;
    position: relative;
    padding: 20px 0 48px;
    height: fit-content;
    outline: none;
    box-shadow: none;
    &:active {
        outline: none;
    }
    &__close {
        text-align: right;
        button {
            cursor: pointer;
            border-width: 0px;
            background-color: transparent;
            padding-right: 20px;
        }
    }
}
