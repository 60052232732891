.tableWrapper {
  position: relative;
  .headerTable {
    position: absolute;
  }
  table {
    border-collapse: collapse;
    width: 100%;
    font-family: 'Work Sans';
    .checkboxColumn {
      width: 100px;
    }
    .deleteFunc {
      cursor: pointer;
      width: 40px;
      height: 40px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      right: 10px;
      &:hover {
        background-color: #f4f4f4;
      }
    }
    .titleCellSelected {
      color: #161616;
      font-size: 14px;
    }
    .thActions, .deleteTh {
      width: 50px;
    }
    .checkBoxCell {

    }
    .blockIcon {
      padding-left: 11px;
    }
    .rowSelected {
      td {
        background-color: #F4F4F4;
      }
    }
    .blockRow {
      td {
        background-color: unset;
      }
    }
  }
  .emptyData {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    padding: 20px;
  }
  .Pagination {
    margin-top: 24px;
    @media screen and (max-width: 899px) {
      padding-bottom: 24px;
      display: flex;
      justify-content: center;
    }
  }
}