.MenuSelectFormField {
  .MuiPaper-root {
    margin-top: toRem(6);
    box-shadow: 0px 1px 10px rgba(29, 45, 73, 0.102);
    border-radius: 4px;
    .MuiButtonBase-root.MuiMenuItem-root {
      font-weight: 500;
      font-size: toRem(16);
      line-height: toRem(20);
      color: $PrimaryText;
      background-color: $ContentColor;
      &:active {
        background-color: $ActiveItem;
      }
    }
    .MuiButtonBase-root.Mui-selected {
      background-color: $ActiveItem;
    }
  }
}