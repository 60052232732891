@import 'styles/scss/variable.scss';
.sidebarDesktopWrapper {
    @import './style.module.scss';
    overflow-x: scroll;
    width: toRem(500);
    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    :global(.MuiListItemButton-root) {
        align-items: flex-start;
        -webkit-align-items: flex-start;
        -webkit-box-align: flex-start;
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
        -webkit-box-align:start;
        // flex-grow: 0;
        font-size: toRem(16);
        // line-height: toRem(15);
        padding: toRem(16) toRem(16);
        width: fit-content;
        height: toRem(48);
        box-sizing: border-box;
        line-height: toRem(17);
        :global(.MuiListItemText-root) {
            margin: 0;
        }
    }
    :global(.MuiBox-root) {
        width: toRem(900);
    }
    .measureItem {
        padding-left: 37px;
    }
}