@import 'styles/scss/variable.scss';
.detailPageWrapper {
  :global(.CardSimpleWrapper) {
    padding: 0.857rem 0.857rem;
    :global(.titleCard) {
      margin-bottom: 15px;
    }
  }
  :global(.pataramerPaper) {
    padding: toRem(16) toRem(12);
  }
  @media screen and (max-width: $screen-sm-workout-max) {
    max-width: 100vw;
  }
  .compareTableItem {
    :global(.CardSimpleWrapper) {
      padding: toRem(12) toRem(12);
    }
  }
  @media screen and (min-width: 1024px) and (max-width: $screen-sm-workout-max) {
    .containerGridMain {
      margin-top: 10px;
      :global(.chart-name) {
        max-width: 250px;
      }
      :global(.workout-chart-component) {
        padding: 0px 0px;
      }
    }
  }
  .emptyChartWrapper {
    height: toRem(612);
    @media screen and (min-width: 1200px) and (max-width: 1399px) {
      height: toRem(580);
    }
  }
  @media screen and (min-width: 1024px) {
    :global(.workoutChartCard) {
      height: toRem(660);
    }
    :global(.workoutListCard) {
      :global(.history-section-component) {
        height: 100%;
      }
    }
  }
}