@import 'styles/scss/fontSizeByRem.scss';

.WeekStreakHocWrapper {
  .gameLevelGrid {
    order: 3;
  }
  .streakLevelGrid {
    order: 2;
  }
  .siceWorkoutLevelGrid {
    order: 1;
  }
  @media screen and (max-width: 1250px) {
    padding: toRem(0) toRem(12);
    width: 100vw;
    box-sizing: border-box;
    margin-top: toRem(12);
    :global(.MuiGrid-container) {
      margin: 0;
      padding: 0;
      width: 100%;
      @media screen and (min-width: 1200px) and (max-width: 1250px) {
        gap: 16px;
        margin-top: 16px;
        margin-bottom: -16px;
        :global(.MuiGrid-item) {
          max-width: calc((100% - 48px)/3)
        }
      }
      :global(.MuiGrid-item) {
        width: 100%;
        margin: 0;
        padding: 0;
        margin-bottom: toRem(12);
      }
    }
  }
}