.pause-or-cancel {
    .more-detail-list {
        .more-detail-item {
            font-family: Work Sans;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            margin-bottom: 8px;
        }
        .more-detail-last-item {
            margin-bottom: 0px;
        }
    }
}

@media screen and (max-width: 768px) {
    .pause-or-cancel {
        margin-top: 24px;
        margin-bottom: 24px;
        padding-left: 12px;
        padding-right: 12px;
        .more-detail-list {
            padding-left: 17px;
            margin-bottom: 32px;
        }
    }
}
