.form-request {
    position: relative;
    // width: 800px;
    max-width: 800px;
    margin: 24px auto;
    background-color: #fff;
    border-radius: 16px;
    padding: 20px;
    &-header {
        // display: flex;
        position: relative;
        margin-bottom: 56px;
    }
    &-icon {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
    }
    &-title {
        text-align: center;
        font-family: "GRIFTER";
        font-weight: 700;
        font-size: 24px;
        color: #161616;
    }
    &-content {
        width: 100%;
        &-item {
            margin-bottom: 25px;
            label,
            p {
                color: #161616;
                font-size: 16px;
                font-family: "Work Sans";
                font-weight: 500;
            }
        }
        &-radio-options {
            width: 70%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &-button {
            text-align: center;
            width: 40%;
            margin: 0 auto;
        }
    }
}

@media screen and (max-width: 767px) {
    .form-request {
        // width: 450px;
        // max-width: 450px;
        &-title {
            text-align: center;
            font-family: "GRIFTER";
            font-weight: 700;
            font-size: 20px;
            color: #161616;
        }
        &-content {
            &-radio-options {
                flex-direction: column;
                align-items: flex-start;
            }
            &-button {
                width: 75%;
            }
        }
    }
}

@media screen and (min-width: 767px) {
    .form-request {
        padding: 20px 60px;
    }
}
