@import 'styles/scss/variable.scss';
.InputDelayWrapper {
  position: relative;
  background: #fff;
  box-sizing: border-box;
  height: toRem(36);
  border-radius: toRem(8);
  display: flex;
  align-items: center;
  input {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 100px;
      background-color: #fff;
      padding: 0px toRem(40) 0px toRem(15);
      border: 1px solid $BorderColor;
      box-sizing: border-box;
      font-size: toRem(14);
      &:focus {
          border: 1px solid $PrimaryText;
          outline: 0;
      }
      &:focus-visible {
          outline: 0;
      }
  }
  div {
    position: absolute;
    right: toRem(14);
    width: toRem(20);
    height: toRem(20);
    svg {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }
}