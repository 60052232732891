.MachineId {
  .machineRow {
    display: flex;
    gap: 0px;
    width: fit-content;
    .shortText {
      display: inline-block;
      width: 108px;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }
    .numberText {
      width: 23px;
      height: 23px;
      background-color: #E6E6E6;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      bottom: 2px;
    }
  }
}

.contentTooltip {
  font-size: 14px;
  color: #161616;
  padding: 0px 16px;
  .coppyWrapper {
    position: relative;
    height: 20px;
    width: 100%;
    margin-top: 10px;
    .copyButton {
      font-size: 12px;
      font-weight: 500;
      padding: 4px 8px;
      border-radius: 4px;
      border: 1px solid;
      border-color: #1e4976;
      color: #e2edf8;
      background-color: rgba(23, 58, 94, 0.5);
      cursor: pointer;
      position: absolute;
      right: 0;
    }
  }
}
