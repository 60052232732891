@import "styles/scss/fontSizeByRem.scss";

.LoginLayoutWrapper {
  display: grid;
  width: 100vw;
  height: fit-content;
  justify-content: center;
  box-sizing: border-box;
  background-color: #f3f4f7;
  overflow-x: hidden;
  .LoginBody {
    overflow-x: hidden;

    // max-width: 1440px;
    width: 100%;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: minmax(0, toRem(880)) minmax(0, toRem(560));
    // display: flex;
    .leftContent {
      // width: 62%;
      overflow-x: hidden;
      width: 100%;
      height: fit-content;
      background-color: #f7f3f4;
      box-sizing: border-box;
    }
    .rightContent {
      // width: 38%;
      overflow-x: hidden;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      background-color: white;
      padding: 0 toRem(80);
      @media screen and (max-width: 1023px) {
        padding: 0 toRem(35);
      }
    }
    @media screen and (max-width: 1023px) {
      grid-template-columns: auto;
    }
  }
}
