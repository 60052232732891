.modal {
    position: relative;
    border: none;
    box-shadow: none;
    outline: none;
    background-color: white;
    width: 568px;
    border-radius: 10px;
    .modal-header {
        text-align: right;
        padding-right: 10px;
        padding-top: 10px;

        button {
            border: none;
            background-color: transparent;
            cursor: pointer;
        }
    }

    .modal-content {
        padding-top: 20px;
        padding-bottom: 80px;
        min-height: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-self: center;
        img {
            width: 150px;
            height: 150px;
            object-fit: cover;
        }
        &-name {
            font-size: 24px;
            font-weight: 700;
            font-family: "GRIFTER";
            color: #161616;
            margin-bottom: 10px;
        }
        &-description {
            font-family: "Work Sans";
            color: #161616;
            font-size: 16px;
        }
    }
    .modal-footer {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100px;
        background-color: #fff;
        outline: none !important;
        border: "0px solid transparent" !important;
        &__content {
            padding: 30px;
            display: flex;
            justify-content: center;
        }
        button {
            width: 48%;
            padding: 0 10px;
        }
        isLoading {
            cursor: not-allowed;
        }
    }
}

@media screen and (max-width: 576px) {
    .modal {
        width: 90%;
        margin: 0 auto;
    }
}
