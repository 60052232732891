@import 'styles/scss/variable.scss';
.ChangeEmailConfirmPage {
  text-align: center;
  min-height: 50vh;
  .ChangeEmailErrorPage {
    .title {
      font-family: GRIFTER;
      font-size: 20px;
      text-transform: uppercase;
      font-weight: 700;
      line-height: 20px;
      text-align: center;
      margin-top: 32px;
      margin-bottom: 32px;
    }
    .buttonGroup {
      button {
        width: 320px;
      }
    }
  }
  .ChangeEmailSuccessPage {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: $zIndexMax;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    .title {
      font-family: GRIFTER;
      font-size: 20px;
      text-transform: uppercase;
      font-weight: 700;
      line-height: 20px;
      text-align: center;
      margin-top: 32px;
    }
    .desc {
      font-family: Work Sans;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
      margin-top: 12px;
    }
    .descSuccess {
      font-family: Work Sans;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
      margin-top: 12px;
    }
    .descSuccess1 {
      font-family: Work Sans;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      text-align: center;
      margin-top: 10px;
      color: #808080;
      span {
        font-weight: 500;
      }
    }
    .buttonGroup {
      margin-top: 32px;
      button {
        width: 320px;
      }
    }
  }
}