.MemberLink {
  display: flex;
  justify-content: flex-end;
  width: fit-content;
  align-items: center;
  cursor: pointer;
  border: 1px solid #FF4106;
  padding: 8px 8px;
  z-index: 9;
  border-radius: 100px;
  .text {
    color: #F5490F;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    padding-right: 12px;
  }
  .icon {
    position: relative;
    top: 1px;
    cursor: pointer;
  }
}