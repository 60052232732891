// Primary color
$white-smoke: #f4f4f4;
$light-gray: #f2f2f2;
$gray: #939393;
$liver: #4f4f4f;
$primary-black: #010101;
$secondary-black: #161616;
$reddish-orange: #f5490f;
$mango-orange: #f98249;
$atomic-tangerine: #fca56e;
$peach-orange: #feca9e;
$white: #ffffff;
$green: #4caf50;

// Font size
$base-font-size: 18px;
$header-1: 6.845em;
$header-2: 4.236em;
$header-3: 2.441em;
$header-4: 1.953em;
$header-5: 1.563em;
$body-title: 1.25em;

$big-title: 20px;
$big-title-2: 18px;
$small: 14px;
$very-small: 12px;

$screen-xs-max: 767px;
$screen-sm-min: 767px;
$screen-sm-max: 991px;
$screen-md-min: 992px;
$screen-md-max: 1199px;
$screen-lg-min: 1200px;

$bg-not-white: #f0f0f0;

// Function
@mixin font-style($family, $size, $color) {
  font-family: $family !important;
  font-size: $size !important;
  color: $color !important;
}

@mixin custom-font-style($family, $size, $color) {
  font-family: $family;
  font-size: $size;
  color: $color;
}

// Remove style
@mixin remove-all-btn-style {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

@mixin remove-highlight-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
