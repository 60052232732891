@import 'styles/scss/variable.scss';
.ReceiptsWrapper {
  @media screen and (max-width: 600px) {
    :global(.MuiPagination-root) {
      ul {
        gap: toRem(6);
      }
      li {
        width: toRem(32);
        height: toRem(32);
      }
      :global(.MuiPaginationItem-root) {
        padding: 0px 6px;
        min-width: unset;
        width: 100%;
        height: 100%;
        min-height: unset !important;
        border-radius: toRem(32);
        box-sizing: border-box;
        margin: 0;
        display: flex;
        align-items: center;
      }
    }   
  }
}