.InviteSuccessToast {
  min-height: 52px;
  background-color: #161616;
  color: white;
  position: relative;
  bottom: 180px;
  border-radius: 8px;
  padding: 16px 24px;
  gap: 30px;
  min-width: 334px;
  :global(.Toastify__close-button) {
    opacity: 1;
    position: relative;
    top: 2px;
    svg {
      fill: white;
    }
  }
  .content {
    display: flex;
    width: fit-content;
    gap: 32px;
    .view {
      a {
        color: #80DE9E;
      }
    }
  }
}
