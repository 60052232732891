@import 'styles/scss/variable.scss';

.verticalTabs {
  :global(.MuiTabs-vertical) {
    border: unset;
    :global(.MuiButtonBase-root.Mui-selected) {
      font-weight: 700;
      color: $PrimaryText
    }
    :global(.MuiButtonBase-root) {
      font-family: 'GRIFTER';
      font-size: toRem(16) !important;
      line-height: 17px;
      align-items: flex-start;
      -webkit-align-items: flex-start;
      -webkit-box-align: flex-start;
      justify-content: flex-start;
      -webkit-justify-content: flex-start;
      -webkit-box-align:start;
      padding: 1rem 1.143rem;
      color: $color-gray;
    }
    :global(.MuiTabs-indicator) {
      background-color: $color-primary-red;
      width: toRem(6) !important;
      height: toRem(40) !important;
      border-radius: toRem(20);
      position: absolute;
      left: 0;
    }
  }
}
.horizontalTabs {
  :global(.MuiTabs-root) {
    border: unset;
    :global(.MuiButtonBase-root.Mui-selected) {
      font-weight: 700;
      color: $PrimaryText
    }
    :global(.MuiButtonBase-root) {
      font-family: 'GRIFTER';
      font-size: toRem(16) !important;
      line-height: toRem(17);
      align-items: flex-start;
      -webkit-align-items: flex-start;
      -webkit-box-align: flex-start;
      justify-content: flex-start;
      -webkit-justify-content: flex-start;
      -webkit-box-align:start;
      padding: toRem(17) toRem(12);
      color: $color-gray;
    }
    :global(.MuiTabs-indicator) {
      background-color: $color-primary-red;
      height: toRem(5) !important;
      border-radius: toRem(20);
    }
  }
}