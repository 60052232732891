//Define font-size for html root. We can use rem unit instead px to easy for responsive.
//base size width on desktop: 1440px
//base size width on mobile: 420px
@use "sass:math";
$baseSize: 14;
@function calculateBaseFontMoile() {
  $vw-context: (420*.01) * 1px;
  @return calc(14px/$vw-context) * 1vw;
}
@function calculateBaseFontDesktop() {
  $vw-context: (1440*.01) * 1px;
  @return calc(14px/$vw-context) * 1vw;
}
html {
  @media screen and (min-width: 1700px) {
    font-size: 14px;
    $baseSize: 14;
  }
  @media screen and (max-width: 1699px) {
    font-size: 14px;
    $baseSize: 14;
  }
  @media screen and (min-width: 1025px) and (max-width: 1439px) {
    font-size: calculateBaseFontDesktop();
    $baseSize: 14;
  }
  @media screen and (max-width: 1024px) {
    font-size: 14px;
    $baseSize: 14;
  }
  @media screen and (max-width: 600px) {
    font-size: calculateBaseFontMoile();
    $baseSize: 14;
  }
  // @media screen and (max-width: 414px) {
  //   font-size: 14px;
  //   $baseSize: 14;
  // }
  // @media screen and (max-width: 410px) {
  //   font-size: 13px;
  //   $baseSize: 13;
  // }
  // @media screen and (max-width: 375px) {
  //   font-size: 12px;
  //   $baseSize: 12;
  // }
  // @media screen and (max-width: 359px) {
  //   font-size: 11px;
  //   $baseSize: 11;
  // }
  // @media screen and (max-width: 310px) {
  //   font-size: 10px;
  //   $baseSize: 10;
  // }
  // @media screen and (max-width: 280px) {
  //   font-size: 9px;
  //   $baseSize: 9;
  // }
}

@function toRem($size) {
  @return calc($size / $baseSize) * 1rem;
}