.Status {
  display: flex;
  gap: 8px;
  align-items: center;
  .icon {
    position: relative;
    top: 2px;
  }
  @media screen and (max-width: 900px) {
    .text {
      display: none;
    }
  }
}