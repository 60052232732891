.MuiButton-root {
  box-shadow: none !important;
  @media screen and (max-width: 900px) {
      width: 100%;
  }
}
.MuiButton-sizeMedium,
.MuiButton-outlinedSizeMedium,
.MuiButton-containedSizeMedium {
  min-height: 48px;
  border-radius: 80px !important;
  min-width: 120px !important;
}
.MuiButton-containedSecondary {
  &:hover {
      background-color: #010101 !important;
  }
}
.MuiTextField-root {
  border: none;
  outline: none !important;
  input {
      font-size: 16px;
      font-family: Work Sans;
      color: #333333;
      height: 1.834em;
  }
}
.MuiOutlinedInput-root {
  &:hover {
      fieldset {
          border-color: rgba(0, 0, 0, 0.5) !important;
      }
  }
}
.Mui-focused {
  fieldset {
      border-color: rgba(0, 0, 0, 0.5) !important;
      border-width: 1px !important;
  }
}
.MuiInputBase-inputSizeSmall {
  input {
      height: 1.834em;
  }
}
.MuiFormHelperText-root {
  color: red !important;
  background: transparent !important;
}
.MuiSelect-select {
  div {
      height: 33px;
      line-height: 30px;
  }
}
.MuiInputLabel-root {
  font-size: 16px !important;
  top: 3px !important;
  color: #808080 !important;
  background: #fff !important;
  padding-right: 8px !important;
}
.MuiInputLabel-shrink {
  transform: translate(14px, -12px) scale(0.75) !important;
}
.MuiInputBase-root {
  div {
      font-size: 16px;
      font-family: Work Sans;
      color: "#33333";
      height: 1.834em !important;
      line-height: 30px;
  }
}
.MuiFormControlLabel-root > span {
  font-size: 16px;
}