@import 'styles/scss/variable.scss';
.InviteButton {
  display: flex;
  height: toRem(36);
  background-color: #FF4106;
  color: white;
  align-items: center;
  justify-content: center;
  gap: toRem(12.27);
  border-radius: 100px;
  border: none;
  padding: 0 toRem(12);
  cursor: pointer;
  font-size: toRem(12);
  font-family: MonoSpec;
  .icon {
    svg {
      width: toRem(11.67);
      height: toRem(11.67);
    }
  }
}