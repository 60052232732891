@function toVwMobile($px-px) {
  @return ($px-px * 100vw) / 390px;
}
@function toVwDesk($px-px) {
  @return ($px-px * 100vw) / 1440px;
}
@function toVwIpad($px-px) {
  @return ($px-px * 100vw) / 1024px;
}
.MembershipDetail {
  animation-name: animateLoadPage;
  animation-duration: 1s;
  @keyframes animateLoadPage {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
  }
  min-height: 50vh;
  .membershipLink {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    :global(.MembershipLinkBtn) {
      @media screen and (max-width: 1440px) {
        width: toVwDesk(186px);
        div {
          font-size: toVwDesk(16px);
        }
      }
      @media screen and (max-width: 899px) {
        width: 50%;
        div {
          font-size: 16px;
        }
      }
      width: 186px;
      display: flex;
      justify-content: center;
    }
  }
  @media screen and (max-width: 600px) {
    .memberPaper {
      padding: 16px 16px;
      :global(.MuiDivider-root) {
        display: none;
      }
    }
    .membershipBoxWrapper {
      padding: 24px 12px;
    }
    .ReceiptsWrapper {
      border-top: 1px solid;
      padding-top: 16px;
      margin-top: -24px;
    }
    .membershipLink {
      display: flex;
      justify-content: flex-start;
      border-bottom: 1px solid #808080;
      padding: 16px 0;
      margin-bottom: 16px;
      margin-top: -48px;
      :global(.MembershipLinkBtn) {
        div {
          font-size: toVwMobile(12px);
        }
      }
    }
  }
}