@function toVW($px-px) {
  @return ($px-px * 100vw) / 390px;
}
.modalCustom {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  position: relative;
  width: 100%;
}

.InviteDetailModal {
  background-color: white;
  border-radius: 16px;
  position: absolute;
  text-align: center;
  padding: 48px 12px;
  margin: 0 !important;
  width: 100%;
  max-width: 100vw;
  height: auto;
  bottom: 0px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  box-sizing: border-box;

  &:focus-visible {
    outline: 0;
  }

  .close-btn {
    position: absolute;
    top: 18px;
    right: 18px;
    cursor: pointer;
  }

  .text-field-group {
    padding: 10px 32px;
  }

  .titleModal {
    padding: 48px 32px 8px 32px;
    font-weight: bold;
    font-size: 24px;
    color: #161616;
    font-family: GRIFTER;
    text-transform: uppercase;
  }

  .row {
    text-align: left;
    margin-bottom: 32px;
    .title {
      font-size: 12px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: #808080;
      margin-bottom: 8px;
    }
    .content {
      :global(.avatar) {
        width: toVW(56px);
        height: toVW(56px);
      }
    }
    :global(.textStatus) {
      display: block;
    }
  }

  .buttonGroup {
    position: absolute;
    max-width: 100vw;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    width: 100%;
    left:0;
    bottom: 16px;
    .buttonCustom {
      width: toVW(358px);
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      padding: 12px 16px;
      border: 1px solid #e6e6e6;
      cursor: pointer;
      border-radius: 8px;
      color: #161616;
      font-weight: 500;
      font-size: 16px;
      box-sizing: border-box;
    }
  }
}