@import "../../styles/scss/variable.scss";

.CardSimpleWrapper {
    width: inherit;
    padding: 1.714rem;
    background-color: $ContentColor;
    box-shadow: toRem(2) toRem(2) toRem(24) rgba(147, 147, 147, 0.2);
    border-radius: toRem(16);
    @media screen and (max-width: 767px) {
        box-shadow: unset;
        border-radius: 0;
    }
    .content {
        width: inherit;
        display: contents;
        box-sizing: border-box;
    }
    .title {
        margin-bottom: 10px;
    }
}
