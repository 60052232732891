.MembershipDetailForC2 {
  .membershipLink {
    display: flex;
    justify-content: flex-end;
  }
  @media screen and (max-width: 600px) {
    .memberPaper {
      padding: 16px 16px;
      :global(.MuiDivider-root) {
        display: none;
      }
    }
    .membershipBoxWrapper {
      padding: 24px 12px;
    }
    .ReceiptsWrapper {
      padding-top: 16px;
      border-top: 1px solid;
    }
    .membershipLink {
      display: flex;
      justify-content: flex-start;
      border-bottom: 1px solid #808080;
      padding: 16px 0;
      margin-bottom: 16px;
      margin-top: -48px;
    }
  }
}