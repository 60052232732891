@import "styles/scss/variable.scss";

.CompareTableWrapper {
    overflow: hidden;
    @media screen and (max-width: 1023px) {
        max-width: 100vw;
        // -webkit-overflow-scrolling: touch;
        overflow: hidden;
        padding: 0 10px;
    }
    table {
        // overflow: auto;
        word-break: keep-all;
        @media screen and (max-width: 1023px) {
            // -webkit-overflow-scrolling: touch;
            overflow: hidden;
        }
        td,
        th {
            text-align: center;
            padding: 8px 8px;
            border-right: 1px solid #d9d9d9;
            @media screen and (max-width: 1023px) {
                text-align: left;
                border-right: 0;
                padding: toRem(12) toRem(8);
            }
        }

        tr {
            td,
            th {
                &:last-child() {
                    border-right: 0px;
                    padding-right: 0px;
                }

                &:first-child() {
                    padding-left: 0px;
                    min-width: toRem(60);
                    text-align: left;
                }
            }

            @media screen and (max-width: 600px) {
                td,
                th {
                    &:last-child() {
                        border-right: 0px;
                        padding-right: toRem(12);
                    }

                    &:first-child() {
                        padding-left: toRem(0);
                        padding-right: 0;
                        min-width: toRem(86);
                    }
                    &:nth-child(2) {
                        min-width: toRem(86);
                    }
                }
                th {
                    font-size: toRem(11);
                }
                td {
                    font-size: toRem(14);
                }
            }
        }

        td {
            font-family: "MonoSpec";
            font-size: toRem(14);
            line-height: 17px;
            text-transform: uppercase;
            color: $SecondaryText;
        }

        th,
        :global(.header2) {
            font-weight: 600;
            font-size: toRem(12);
            line-height: 19px;
            text-transform: uppercase;
            color: $BlackColor;
            font-family: "Work Sans";
            @media screen and (max-width: 600px) {
                font-size: toRem(10);
            }
        }
        :global(.header2) {
            text-align: left !important;
        }
        :global(.headerPreviousDay) {
            text-align: left;
            div {
                width: toRem(60);
            }
        }
        :global(.cellLongText) {
            font-size: 12px;
        }
        :global(.cellLongText1) {
            font-size: 10px;
        }
        @media screen and (max-width: 600px) {
            :global(.cellLongText) {
                font-size: calc((100vw / 414) * 12);
            }
            :global(.cellLongText1) {
                font-size: calc((100vw / 414) * 10);
            }
            :global(.previousDayCell) {
                div {
                    width: toRem(60);
                }
            }
            :global(.lastDayCell) {
                div {
                    width: toRem(60);
                }
            }
        }
    }

    .diffenrentCell {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        left: 10px;

        @media screen and (max-width: 1023px) {
            left: 0;
            justify-content: flex-start;
        }

        .upValue {
            color: $SuccessColor;
        }

        .downValue {
            color: $DangerColor;
        }
    }
}
