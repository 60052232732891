.MembershipTable {
  table {
    :global(.trHeader) {
      text-align: left;
      color: #808080;
      font-size: 12px;
      font-weight: 500;
      line-height: 14.08px;
      th {
        padding-bottom: 20px;
      }
    }
    :global(.trRow) {
      text-align: left;
      border-bottom: 1px solid#E6E6E6;
      &:last-child {
        border-bottom: none;
      }
      td {
        padding: 12px 0;
      }
    }
  }
}