.Pagination {
  :global(.MuiButtonBase-root) {
    border: unset;
    background-color: #f4f4f4;
    border-radius: 10px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    font-family: MonoSpec;
  }
  :global(.MuiPaginationItem-root.Mui-selected) {
    background-color: #ff4106;
    color: white;
    &:hover {
      background-color: #ff4106;
      color: white;
    }
  }
  @media screen and (max-width: 500px) {
    @function toVW($px-px) {
      @return ($px-px * 100vw) / 390px;
    }
    :global(.MuiButtonBase-root) {
      min-width: toVW(32px);
      height: toVW(32px);
      font-size: toVW(14px);
      line-height: toVW(18px);
      border-radius: toVW(8px);
    }
  }
}