@import 'styles/scss/variable.scss';
@import './style.scss';
.CollaspeMenuWrapper {
  height: calc(100vh - 76px);
  .contentMenuWrapper {
    position: relative;
    top: 76px;
    width: 100vw;
    :global(.sidebarDesktopWrapper) {
      height: fit-content;
      width: 80%;
      background-color: white;
      overflow: hidden;
      :global(.MuiBox-root) {
        width: 80%;
      }
      :global(.listMenuWrapper) {
        top: toRem(0);
        display: block;
        position: absolute;
        height: fit-content;
        :global(.MuiListItemButton-root) {
          margin: toRem(24);
          width: fit-content;
          align-items: flex-start;
          -webkit-align-items: flex-start;
          -webkit-box-align: flex-start;
          justify-content: flex-start;
          -webkit-justify-content: flex-start;
          -webkit-box-align:start;
          :global(.MuiListItemText-root) {
            text-align: left;
            font-size: toRem(18);
          }
        }
      }
    }
    .closeIcon {
      position: absolute;
      right: toRem(20);
      top: toRem(15);
      :global(.MuiSvgIcon-root) {
        width: toRem(30);
        height: toRem(30);
        fill: #666;
      }
    }
  }
}