@import 'styles/scss/variable.scss';
@import 'styles/scss/fontSizeByRem.scss';
.WeekStreakCardWrapper {
  :global(.CardSimpleWrapper) {
    padding: toRem(16) toRem(16);
    box-sizing: border-box;
    height: toRem(112);
    display: flex;
    box-shadow: toRem(2) toRem(2) toRem(24) rgba(147, 147, 147, 0.2);
    border-radius: toRem(16);
  }
  .weekStreak {
    display: flex;
    align-items: center;
    gap: toRem(16);
    width: 100%;
    .achieve {
      width: toRem(65);
      height: toRem(65);
      position: relative;
      .iconWrapper {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        :global(.weekstreakLevelGroupWrapper) {
          bottom: toRem(10);
          right: toRem(6);
        }
      }
    }
    .rightContent {
      width: 100%;
      .text {
        font-family: 'GRIFTER';
        font-weight: 700;
        font-size: toRem(18);
        line-height: toRem(28);
      }
      .bestAchieve {
        font-family: 'MonoSpec';
        font-weight: 500;
        font-size: toRem(14);
        line-height: toRem(20);
        color: $SecondaryText;
        margin-top: toRem(12);
      }
    }
  }

  @media screen and (max-width:600px) {
    :global(.CardSimpleWrapper) {
      padding: toRem(16) toRem(16);
      box-sizing: border-box;
      height: toRem(112);
      display: flex;
      box-shadow: toRem(2) toRem(2) toRem(24) rgba(147, 147, 147, 0.2);
      border-radius: toRem(16);
    }
    .weekStreak {
      display: flex;
      align-items: center;
      gap: toRem(16);
      .achieve {
        width: toRem(65);
        height: toRem(65);
        position: relative;
        .iconWrapper {
          width: 100%;
          height: 100%;
          svg {
            width: 100%;
            height: 100%;
          }
          box-sizing: border-box;
          :global(.weekstreakLevelGroupWrapper) {
            bottom: toRem(6);
            right: toRem(5);
          }
        }
      }
      .rightContent {
        width: 100%;
        .text {
          font-family: 'GRIFTER';
          font-weight: 700;
          font-size: toRem(18);
          line-height: toRem(28);
        }
        .bestAchieve {
          font-family: 'MonoSpec';
          font-weight: 500;
          font-size: toRem(14);
          line-height: toRem(20);
          color: $SecondaryText;
          margin-top: toRem(12);
        }
      }
    }
  }
}