@import 'styles/scss/variable.scss';

.VerticalBarChartWrapper {
  .chartYTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: $color-gray;
    margin-top: 24px;
  }

  #VerticalBarChart {
    width: 100%;
    @media screen and (max-width: 600px) {
      width: 100vw;
      background-color: white;
    }

    #apexchartsworkoutBarChart {
      @media screen and (max-width: 600px) {
        svg {
          background-color: white !important;
        }
        .apexcharts-xaxis-texts-g {
          .apexcharts-xaxis-label {
            font-size: toRem(12);
          }
        }
      }
    }

    .apexcharts-bar-series.apexcharts-plot-series .apexcharts-series path {
      clip-path: inset(0% 0% -11% 0% round 5px);
    }

    .apexcharts-toolbar {
      display: none;
    }
    .apexcharts-yaxis-label {
      tspan {
        font-size: toRem(16);
        font-family: 'MonoSpec';
        @media screen and (max-width: 600px) {
          font-size: toRem(12);
        }
      }
    }
    .apexcharts-xaxis {
      transform: translateY(toRem(-10));
    }
    .apexcharts-xaxis-texts-g {
      transform: none;
      height: 100px;
      .apexcharts-xaxis-label {
        transform: none;
        margin-top: 20px !important;
        tspan:nth-child(1) {
          font-size: toRem(16);
          font-weight: 600;
          fill: $color-gray;
          @media screen and (max-width: 600px) {
            font-size: toRem(12);
          }
        }

        tspan:nth-child(2) {
          font-family: 'MonoSpec';
          font-weight: normal;
          font-size: toRem(16);
          @media screen and (max-width: 600px) {
            font-size: toRem(12);
          }
        }
      }
    }
  }
}