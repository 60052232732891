@import 'styles/scss/variable.scss';
.MachineCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 12px;
  padding: toRem(7) toRem(24);
  height: 104px;

  .machineInfoBlock {
    display: flex;
    align-items: center;
    gap: toRem(28);
    .logo {
      img {
        width: toRem(80);
      }
    }
    .info1 {
      font-size: toRem(16);
      font-weight: 500;
      line-height: toRem(20);
      text-transform: uppercase;
      color: #808080;
      margin-bottom: toRem(10);
    }
    .info2 {
      font-size: toRem(20);
      font-weight: 500;
      line-height: toRem(20);
      text-transform: uppercase;
      color: #161616;
      margin-bottom: toRem(8);
      font-family: GRIFTER;
    }
    .info3 {
      font-size: toRem(14);
      font-weight: 500;
      line-height: toRem(16);
      text-transform: uppercase;
      color: #808080;
    }
    .machineInfo {}
  }

  .buttonGroup {
    display: flex;
    gap: 18px;

    .deleteButton {
      font-family: MonoSpec;
      background-color: white;
      gap: 8px;
      border-radius: 100px;
      border: 1px solid #161616;
      color: #161616;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .switcheButton {
      font-family: MonoSpec;
      gap: 8px;
      border-radius: 100px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  @media screen and (max-width: 600px) {
    .buttonGroup {
      flex-direction: column;
      :global(.PrimaryButton) {
        height: toRem(24);
        font-size: toRem(12);
      }
    }
  }
  @media screen and (max-width: 500px) {
    height: auto;
    display: block;
    padding: toRem(12) toRem(24);
    .buttonGroup {
      flex-direction: row;
      justify-content: center;
      margin-top: toRem(24);
      :global(.PrimaryButton) {
        height: toRem(48);
        font-size: toRem(16);
        width: toRem(162);
      }
    }
  }
}