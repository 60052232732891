.MachineId {
  .machineRow {
    display: flex;
    gap: 0px;
    width: fit-content;
    .shortText {
      display: inline-block;
      width: 108px;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }
    .numberText {
      width: 23px;
      height: 23px;
      background-color: #E6E6E6;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      bottom: 2px;
    }
  }
}

.contentTooltip {
  font-size: 14px;
  color: #161616;
  padding: 0px 16px;
}
