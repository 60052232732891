@import 'styles/scss/variable.scss';
@import 'styles/scss/fontSizeByRem.scss';
.ExportButtonWrapper {
  button {
    padding: toRem(6);
    width: toRem(45);
    height: toRem(45);
    background: $ContentColor;
    border: 1px solid $BorderColor;
    border-radius: toRem(4);
    cursor: pointer;
    &:active {
      background-color: $GrayColor;
      border-color: $BorderActiveColor;
    }
    &:hover {
      border-color: $BorderActiveColor;
    }
  }
}