@import '../../../styles/scss/fontSizeByRem.scss';
.workoutCalendartWrapper {
  :global(.CardSimpleWrapper) {
    @media screen and (min-width: 960px) {
      height: 100%;
      box-sizing: border-box;
    }
  }
  @media screen and (max-width: 600px) {
    max-width: 100vw;
  }
  position: relative;
  .loadingWrapper {
    width: 100%;
    height: toRem(520);
    z-index: 10;
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
  }
}