.WorkoutDetailMoreWrapper {
  top: 76px !important;
  overflow: auto;
  height: calc(100vh - 76px);
  width: 100vw;
  z-index: 1100 !important;
  .spaceRowDrawer {
    width: 100%;
    height: toRem(20);
    background-color: $BodyColor;
  }
  .MuiDrawer-paperAnchorRight {
    overflow: auto;
    .workoutContentContainer {
      width: 100vw;
      overflow: auto;
      overflow-x: hidden;
      display: block;
      box-sizing: content-box;
      margin-top: 76px;
      .closeWorkoutMoreIcon {
        position: sticky;
        .MuiSvgIcon-root {
          font-size: 30px;
          fill: #666;
          right: toRem(24);
          top: toRem(12);
          position: absolute;
        }
      }
      .workoutMoreStatBoard {
        .parameter-section-component {
          // margin-bottom: 50px;
          // margin-top: -60px;
        }
        .CardSimpleWrapper {
          padding: toRem(24) toRem(12)
        }
      }
      .workoutMoreChart {
        .workout-chart-component {
          margin-top: toRem(20);
          margin-bottom: toRem(10);
          .title-container {
            margin-left: 24px;
            flex-direction: column;
            gap: 8px;
            align-items: flex-start;
            .chart-name {
              font-size: 18px;
            }
            .unitWrapper {
              margin-left: 0;
              bottom: 0;
              .unit-name {
                font-size: 10px;
                line-height: 12px;
                padding: 4px 8px;
              }
            }
          }
          .tabChartButtons {
            width: toRem(366);
            margin-left: toRem(24);
            margin-bottom: toRem(20);
          }
        }
      }
    }
  }
}