@import "styles/scss/variable.scss";
@import "styles/scss/fontSizeByRem.scss";

.history-section-component {
    background-color: $ContentColor;
    border-radius: 1.333em;
    box-sizing: border-box;
    background-color: white;
    height: 100%;
    @media screen and (min-width: 1200px) {
        // height: toRem(991);
    }
    @media screen and (max-width: 1023px) {
        width: 100vw;
        border-radius: none;
        margin-top: 12px;
        height: auto;
    }

    .title-container {
        width: 100%;
        background-color: $BlackColor2;
        display: flex;
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
        align-items: center;
        padding: 1.714rem 1.143rem;
        gap: 1.714rem;
        height: 4.93rem;
        box-sizing: border-box;

        @media screen and (max-width: 1023px) {
            width: 100vw;
            border-radius: 0;
        }

        @media screen and (max-width: 768px) {
            text-align: center;
        }

        .icon-container {
            width: 27.25px;
            height: 18.41px;
        }

        .title-text {
            font-family: "GRIFTER";
            color: white;
            font-style: normal;
            font-weight: bold;
            font-size: 1.2857rem;
            line-height: 28px;
            position: relative;
            margin-top: 8px;
            // bottom: ;
            .workoutDateTime {
                font-family: "MonoSpec";
                font-style: normal;
                font-weight: 400;
                font-size: toRem(16);
                line-height: 20px;
                display: flex;
                align-items: center;
                letter-spacing: 0.01em;
                text-transform: uppercase;
                color: #c3c3c3;
            }
        }
    }

    .history_toolbar_top {
        padding: 0 toRem(12);
        margin-top: toRem(6);
        margin-bottom: toRem(16);
        height: toRem(40);
        @media screen and (max-width: 1024px) {
            height: toRem(60);
        }
        @media screen and (max-width: $screen-sm-workout-max) {
            margin-bottom: 0;
        }

        :global(.timeRaneWrapper) {
            display: flex;
            gap: 20px;
            align-items: center;
            justify-content: space-between;

            :global(.selectField) {
                min-width: calc(100% - 32px - 30px);
            }

            :global(.downloadButton) {
                position: relative;
                width: 32px;

                button {
                    float: right;
                    position: relative;
                    top: toRem(3);
                }
            }
        }
    }

    .history_footer {
        height: toRem(60);
        .textPaging {
            font-size: toRem(14);
            color: $SecondaryText;
        }
    }

    .content_history_wrapper {
        height: toRem(790);

        @media screen and (max-width: 1024px) {
            height: toRem(931);
        }
        @media screen and (max-width: 1023px) {
            height: auto;
            min-height: toRem(300);
        }

        .items_content {
            display: flex;
            background-color: $ContentColor;
            padding: 0 toRem(12);
            padding-right: toRem(24);
            flex-direction: column;

            @media screen and (max-width: 1023px) {
                padding-right: toRem(12);
            }

            :global(.itemHistorySectionWrapper) {
                &:nth-child(1) {
                    margin-top: toRem(16);
                }

                margin-top: toRem(16);
            }
        }

        :global(.scrollBarTrack) {
            right: toRem(8);
            background: #f1f1f1;
        }

        :global(.scrollBarThumb) {
            right: toRem(8);
            background: #d1d1d1;
        }
    }

    :global(.content_history_wrapper_not_haveToolBar) {
        position: relative;
        top: toRem(-60);
    }

    .title-icon {
        font-size: 1rem;
        color: $color-primary-black;
    }

    .title-text {
        font-size: 1rem;
        color: $color-primary-black;
    }
}

.emptyHistoryWrapper {
    width: 100%;

    .emptyHistoryContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        align-items: center;
        margin-top: toRem(80);

        .icon {
            width: toRem(235);
            height: toRem(235);
            background-color: $GrayColor2;
            border-radius: toRem(500);
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                width: toRem(126);
                height: toRem(126);
            }
        }

        .text {
            font-size: toRem(16);
            line-height: toRem(19);
            color: $PrimaryText;
            margin-top: toRem(16);
        }
    }
}
