$backgroundWeekStreak: #FFECE6;
.react-datepicker {
  width: 100% !important;
  height: 100% !important;
  font-family: 'Work Sans' !important;
  font-size: 1rem !important;
  color: $PrimaryText;
  box-sizing: border-box;
  border: none !important;
  margin-top: toRem(35);
  color: $PrimaryText;
  min-height: toRem(392);
  .react-datepicker__month-container {
    width: 100%;
  }

  .react-datepicker__header {
    width: 100%;
    border: none;
    background-color: transparent;

    .react-datepicker__current-month {
      font-weight: 600;
      font-size: toRem(20);
      line-height: toRem(23);
      text-transform: uppercase;
      display: none;
    }
    .react-datepicker__month-dropdown-container {
      margin: 0;
    }
    .react-datepicker__year-dropdown-container {
      margin: 0;
      margin-left: toRem(6);
    }
    .react-datepicker__month-read-view, .react-datepicker__year-read-view {
      font-weight: 600;
      font-size: toRem(20);
      line-height: toRem(23);
      text-transform: uppercase;
      visibility: visible !important;
      &--down-arrow {
        display: none;
      }
    }

    .react-datepicker__month-dropdown {
      background-color: white;
      text-align: left;
      border: none;
      box-shadow: 0px 1px 10px rgba(29, 45, 73, 0.102);
      box-sizing: border-box;
      padding: toRem(5) 0;
      width: 200px;
      .react-datepicker__month-option {
        padding: toRem(3) toRem(16);
        width: 100%;
        box-sizing: border-box;
        font-weight: 500;
        color: $PrimaryText;
        .react-datepicker__month-option--selected {
          display: none;
        }
        &--selected_month {
          background-color: $ActiveItem;
        }
        &:hover {
          background-color: $ActiveItem;
        }
      }
    }

    .react-datepicker__year-dropdown {
      background-color: white;
      text-align: left;
      border: none;
      box-shadow: 0px 1px 10px rgba(29, 45, 73, 0.102);
      box-sizing: border-box;
      padding: toRem(5) 0;
      width: 200px;
      .react-datepicker__year-option {
        padding: toRem(3) toRem(16);
        width: 100%;
        height: 35px;
        align-items: center;
        display: flex;
        box-sizing: border-box;
        font-weight: 500;
        color: $PrimaryText;
        &:nth-child(1) {
          position: sticky;
        }
        &:last-child() {
          position: sticky;
        }
        .react-datepicker__year-option--selected {
          display: none;
        }
        &--selected_year {
          background-color: $ActiveItem;
        }
        &:hover {
          background-color: $ActiveItem;
        }
        .react-datepicker__navigation--years {
          border-left: 2px solid $GrayColor;
          border-top: 2px solid $GrayColor;
          width: 8px;
          height: 8px;
          top:0;
        }
        .react-datepicker__navigation--years-upcoming {
          transform: rotate(43deg);
        }
        .react-datepicker__navigation--years-previous {
          transform: rotate(-135deg);
        }
      }
    }

    .react-datepicker__day-names {
      font-weight: 500;
      margin-top: toRem(33);
      .react-datepicker__day-name {
        &:nth-child(7) {
          margin-right: 0;
        }
      }

      .react-datepicker__day-name {
        // width: toRem(32);
        height: toRem(32);
        min-height: toRem(32);
        border-radius: 100px;
        margin-right: toRem(30);
        @media screen and (max-width: 600px) {
          margin-right: toRem(26);
        }
  
      }
    }
  }

  .react-datepicker__month {
    margin: 0;
    width: 100%;
    box-sizing: border-box;
  }

  .react-datepicker__week {
    width: 100%;
    margin-bottom: toRem(12);

    .react-datepicker__day {
      &:nth-child(7) {
        margin-right: 0;
      }
    }

    box-sizing: border-box;
  }

  .react-datepicker__day {
    width: toRem(55);
    @media screen and (max-width: 600px) {
      width: toRem(52);
    }
    height: toRem(32);
    min-height: toRem(28);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin-right: 0;
    margin-left: 0;
    padding: 0;
    cursor: default;

    &:hover {
      border-radius: 100px;
      background-color: transparent;
    }
    &--today {
      background-color: transparent;
      font-weight: normal;
      // .dateCustom {
      //   border: 1px solid $color-primary-red;
      //   background-color: $ActiveItem;
      //   font-weight: 500;
      //   color: $PrimaryText;
      // }
    }
    &--selected {
      background-color: transparent;
      .dateCustom {
        border: 1px solid $color-primary-red;
        background-color: $ActiveItem;
        font-weight: 500;
        color: $PrimaryText;
      }
    }
    .dateCustom {
      min-width: toRem(32);
      height: toRem(32);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: toRem(100);
    }
    &--outside-month {
      color: $color-gray;
    }
    &--keyboard-selected {
      background-color: transparent;
    }
    &--highlighted {
      background-color: transparent;
      .dateCustom {
        background-color: $color-primary-red !important;
        color: $color-white;
        cursor: pointer;
        &:hover {
          background-color: $color-primary-red;
        }
      }
    }
    &.dateInHighlighRange {
      background-color: $backgroundWeekStreak;
      border-radius: 0;
      &.dateMin {
        background-color: white;
        background-image: linear-gradient(to right, white, $backgroundWeekStreak);
      }
      &.dateMax {
        background-color: white;
        background-image: linear-gradient(to right, $backgroundWeekStreak, white);
      }
    }
    &.todayByTimezone {
      .dateCustom {
        border: 1px solid $color-primary-red;
        background-color: #FDDBCF;
      }
    }
  }

  // arrows next, prev days
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__navigation-icon::before {
    border-color: $color-gray;
    border-style: solid;
    border-width: toRem(2) toRem(2) 0 0;
    content: "";
    display: block;
    height: toRem(8);
    position: absolute;
    top: toRem(8);
    width: toRem(8);
  }

}