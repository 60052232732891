@import "../../../styles/scss/variable.scss";
.CompareStatsWrapper {
    overflow: hidden;
    @media screen and (max-width: 1023px) {
        :global(.CardSimpleWrapper) {
            padding: 0;
        }
    }
    .titleCardWrapper {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        margin-bottom: toRem(5);
        @media screen and (max-width: 1023px) {
            padding: 0 toRem(12);
            margin-left: toRem(-12);
        }
        @media screen and (max-width: 600px) {
            // padding: 0;
            .titleCard {
                font-size: toRem(15);
            }
        }
        align-items: center;
        .titleCard {
            // margin-left: toRem(10);
            margin-left: 10px;
        }
        .select {
            position: relative;
            top: toRem(-5);
            width: toRem(175);
        }
    }
}
