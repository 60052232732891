.upgrade_plan_modal {
    position: relative;
    border: none;
    box-shadow: none;
    outline: none;
    background-color: white;
    border-radius: 16px;
    overflow: hidden;
    .upgrade_plan_modal-header {
        text-align: right;
    }
    .upgrade_plan_modal-content {
        width: 640px;
        border-radius: 5px;
        padding-bottom: 48px;
        .upgrade_plan_modal-img {
            text-align: center;
            margin-bottom: 56px;
            img {
                width: 160px;
                height: 160px;
                object-fit: contain;
            }
        }
        .upgrade_plan_modal-title {
            font-family: GRIFTER;
            font-size: 24px;
            font-weight: 700;
            line-height: 24.48px;
            letter-spacing: 0.01em;
            text-align: center;
            margin: 0 0 24px;
        }
        .upgrade_plan_modal-desc {
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            margin: 0 0 56px;
        }
        .upgrade_plan_modal-button {
            text-align: center;
        }
    }
}

@media screen and (max-width: 1024px) {
    .upgrade_plan_modal {
        .upgrade_plan_modal-content {
            width: 520px;
        }
    }
}

@media screen and (max-width: 600px) {
    .upgrade_plan_modal {
        width: 340px;

        .upgrade_plan_modal-content {
            width: 100%;
            padding-bottom: 32px;
            .upgrade_plan_modal-img {
                img {
                    width: 120px;
                    height: 120px;
                }
            }
            .upgrade_plan_modal-title {
                font-size: 20px;
                line-height: 20px;
            }
            .upgrade_plan_modal-desc {
                padding: 0 24px;
                margin: 0 0 32px;
            }
            .upgrade_plan_modal-button {
                padding: 0 24px;
            }
        }
    }
}
