.membership_offer_invalid {
    position: relative;
    border: none;
    box-shadow: none;
    outline: none;
    background-color: white;
    border-radius: 16px;
    padding-bottom: 48px;
    .membership_offer_invalid-header {
        text-align: right;
    }
    .membership_offer_invalid-content {
        width: 640px;
        .membership_offer_invalid-img {
            text-align: center;
            img {
                width: 160px;
                height: 160px;
                object-fit: contain;
            }
        }
        .membership_offer_invalid-title {
            font-family: GRIFTER;
            font-size: 24px;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 0.01em;
            text-align: center;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            margin-bottom: 16px;
            margin-top: 56px;
        }
        .membership_offer_invalid-desc {
            //styleName: Body/2;
            font-family: Work Sans;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            text-align: center;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            margin: 0;
            margin-bottom: 56px;
        }
        .membership_offer_invalid-btn {
            text-align: center;
            width: 40%;
            margin: auto;
        }
    }
}

@media screen and (max-width: 768px) {
    .membership_offer_invalid {
        width: 94%;
        .membership_offer_invalid-content {
            width: 100%;
            .membership_offer_invalid-img {
                object-fit: contain;
            }
            .membership_offer_invalid-desc {
                padding: 0 20px;
            }
            .membership_offer_invalid-btn {
                width: 80%;
            }
        }
    }
}
