@import "../../styles/scss/variable.scss";
.parameter-section-component {
    .parameter-container {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(1, 1fr);
        column-gap: toRem(14);
        row-gap: toRem(16);
        @media (max-width: 1200px) {
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: repeat(1, 1fr);
            column-gap: 16px;
            row-gap: 16px;
        }
        @media (max-width: 600px) {
            grid-template-columns: 1fr 1fr;
            // grid-template-rows: repeat(5, 1fr);
            column-gap: 12px;
            row-gap: 12px;
            .scoreParam {
                order: 1;
            }
            .outputParam {
                order: 3;
            }
            .caloriesParam {
                order: 5;
            }
            .metersParam {
                order: 7;
            }
            .strokesParam {
                order: 9;
            }
            .timeParam {
                order: 2;
            }
            .avgWattParam {
                order: 4;
            }
            .avgCalsParam {
                order: 6;
            }
            .avg500MParam {
                order: 8;
            }
            .avgSpmParam {
                order: 10;
            }
        }

        @media (max-width: 378px) {
            // grid-template-columns: 1fr 1fr;
            // grid-template-rows: repeat(5, 1fr);
            column-gap: 8px;
            row-gap: 8px;
        }
    }
}
