.Actions {
  .blockActionIcon {
    cursor: unset !important;
    svg {
      opacity: 0.5;
    }
  }
}
.actionsMenu {
  .itemAction {
    display: flex;
    gap: 10.67px;
    align-items: flex-start;
    padding: 18px 18px;
    cursor: pointer;
    &:hover {
      background-color: #F4F4F4;
    }
    .icon {

    }
    .item {
      font-size: 16px;
      font-weight: 500;
    }
  }
}