@import 'styles/scss/fontSizeByRem.scss';
.levelGroupWrapper {
  position: relative;
  width: toRem(85);
  height: toRem(85);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  .icon {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    // background-color: aqua;
  }
  .levelTextWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    :global(.levelText) {
      font-family: 'MonoSpec';
      font-weight: 500;
      font-size: toRem(18);
      margin-bottom: toRem(10);
    };
    :global(.levelTextLong) {
      font-size: toRem(14);
    };
  }
  @media screen and (max-width: 600px) {
    width: toRem(75);
    height: toRem(75);
  }
}