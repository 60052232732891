@import 'styles/scss/variable.scss';

.LineChartWrapper {
  position: relative;

  #customLineChart {
    box-sizing: border-box;
    // background-color: yellow;
    margin-left: toRem(-15);

    @media screen and (max-width: 600px) {
      min-height: 395px !important;
      width: 100vw;
      padding-right: toRem(15);
      margin-left: toRem(0);
    }
  }

  .workout-line-chart-label-y-wrapper {
    min-height: toRem(20);
    width: toRem(25);
    // background-color: aqua;
    box-sizing: border-box;
  }

  .workoutLineChart-label-y {
    font-size: toRem(16);
    line-height: 19px;
    opacity: 0.8;
    color: $PrimaryText;

    // left: toRem(-25);
    @media screen and (max-width: 600px) {
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      margin-left: 12px;
      color: $color-gray;
      margin-top: 10px;
      height: auto;
    }
  }

  #apexchartscustomLineChart {

    // width: 1100px !important;
    // overflow-x: hidden;
    // overflow-y: hidden;
    // height: 380px !important;
    svg {
      // height: 380px !important;
    }
  }

  // .apexcharts-toolbar {
  //   display: none;
  // }

  .apexcharts-xaxis-label {
    fill: $color-gray,
  }

  .apexcharts-yaxis-title {
    // margin-right: 15px !important;
  }

  .apexcharts-mobile-yaxis-label {
    font-size: toRem(12);
  }

  .apexcharts-legend {
    gap: 10px;
    align-items: baseline;
    width: fit-content;
    right: 0 !important;
    inset: auto 0px 15px auto !important;

    @media screen and (max-width: 600px) {
      width: 100vw;
      max-width: 100vw !important;
      // position: relative !important;
      // margin-bottom: toRem(80) !important;
      box-sizing: border-box;
      padding: 0;
    }

    .apexcharts-legend-series {
      border: 0.793573px solid $BorderColor;
      border-radius: 3.17429px;
      margin: 0 !important;

      @media screen and (max-width: 600px) {
        min-width: 85vw;
        .apexcharts-legend-text {
          font-size: toRem(12) !important;
        }
      }

      &:nth-child(1) {
        order: 1;

        .apexcharts-legend-marker {
          background-color: $color-primary-red !important;
        }
      }

      &:nth-child(2) {
        .apexcharts-legend-marker {
          background-image: url('./dotMarker.png') !important;
          background-repeat: repeat-x !important;
          background-size: contain !important;
        }
      }

      .apexcharts-legend-marker {
        border-radius: 0 !important;
        height: 3px !important;
        border-width: 10px 10px !important;
        border-radius: 3.17429px !important;
        cursor: default !important;
      }

      .apexcharts-legend-text {
        padding: toRem(5) toRem(10) !important;
        box-sizing: border-box !important;
        font-family: 'Work Sans' !important;
        line-height: 19px !important;
        text-transform: uppercase;
        color: $SecondaryText !important;
      }
    }
  }

  .apexcharts-xaxis-texts-g {
    -webkit-transform-origin: 10px 0px !important;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);

    .apexcharts-xaxis-label {
      fill: $color-gray;
      -webkit-transform-origin: 10px 0px !important;
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      margin-top: 20px !important;

      &y {
        fill: 435.3335950622559;
      }

      ;

      tspan:nth-child(1) {
        fill: $SecondaryText;
        font-size: toRem(16);
        font-weight: 500;
        font-family: 'MonoSpec';
      }

      tspan:nth-child(2) {
        font-weight: normal;
      }

      @media screen and (max-width: 600px) {
        tspan:nth-child(1) {
          font-size: toRem(12);
        }

        tspan:nth-child(2) {
          font-size: toRem(12);
        }
      }
    }
  }

  .apexcharts-xaxis-label-365days {
    tspan:nth-child(1) {
      font-family: 'Work Sans' !important;
    }

    transform: translate(toRem(25), toRem(10)) !important;

    &:nth-child(53) {
      transform: translate(toRem(10), toRem(10)) !important;
    }

    // &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    // &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9),
    &:nth-child(10),
    &:nth-child(11),
    // &:nth-child(12),
    &:nth-child(13),
    &:nth-child(14),
    &:nth-child(15),
    &:nth-child(16),
    &:nth-child(17),
    // &:nth-child(18),
    &:nth-child(19),
    &:nth-child(20),
    &:nth-child(21),
    &:nth-child(22),
    &:nth-child(23),
    // &:nth-child(24),
    &:nth-child(25),
    &:nth-child(26),
    &:nth-child(27),
    &:nth-child(28),
    &:nth-child(29),
    // &:nth-child(30),
    &:nth-child(31),
    &:nth-child(32),
    &:nth-child(33),
    &:nth-child(34),
    &:nth-child(35),
    // &:nth-child(36),
    &:nth-child(37),
    &:nth-child(38),
    &:nth-child(39),
    &:nth-child(40),
    &:nth-child(41),
    // &:nth-child(42),
    &:nth-child(43),
    &:nth-child(44),
    &:nth-child(45),
    &:nth-child(46),
    // &:nth-child(47),
    &:nth-child(48),
    &:nth-child(49),
    &:nth-child(50),
    &:nth-child(51),
    &:nth-child(52) {
      display: none;
    }

    @media screen and (max-width: 1000px) {

      // &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8),
      &:nth-child(9),
      &:nth-child(10),
      &:nth-child(11),
      // &:nth-child(12),
      &:nth-child(13),
      &:nth-child(14),
      &:nth-child(15),
      &:nth-child(16),
      &:nth-child(17),
      &:nth-child(18),
      &:nth-child(19),
      &:nth-child(20),
      &:nth-child(21),
      &:nth-child(22),
      &:nth-child(23),
      // &:nth-child(24),
      &:nth-child(25),
      &:nth-child(26),
      &:nth-child(27),
      &:nth-child(28),
      &:nth-child(29),
      &:nth-child(30),
      &:nth-child(31),
      &:nth-child(32),
      &:nth-child(33),
      &:nth-child(34),
      &:nth-child(35),
      // &:nth-child(36),
      &:nth-child(37),
      &:nth-child(38),
      &:nth-child(39),
      &:nth-child(40),
      &:nth-child(41),
      &:nth-child(42),
      &:nth-child(43),
      &:nth-child(44),
      &:nth-child(45),
      &:nth-child(46),
      &:nth-child(47),
      &:nth-child(48),
      &:nth-child(49),
      &:nth-child(50),
      &:nth-child(51),
      &:nth-child(52) {
        display: none;
      }
    }
  }

  .apexcharts-xaxis-label-180days {
    display: none;
    tspan:nth-child(1) {
      font-family: 'Work Sans' !important;
    }
    transform: translate(toRem(25), toRem(10)) !important;
    &:nth-child(1),
    &:nth-child(17),
    &:nth-child(33),
    &:nth-child(50),
    &:nth-child(65),
    &:nth-child(81),
    &:nth-child(97),
    &:nth-child(113),
    &:nth-child(129),
    &:nth-child(145),
    &:nth-child(161),
    &:nth-child(177) {
      display: block;
    }
    @media screen and (max-width: 1000px) {
      &:nth-child(1),
      &:nth-child(17),
      &:nth-child(33),
      &:nth-child(50),
      &:nth-child(65),
      &:nth-child(81),
      &:nth-child(97),
      &:nth-child(113),
      &:nth-child(129),
      &:nth-child(145),
      &:nth-child(161),
      &:nth-child(177) {
        display: none;
      }
      &:nth-child(1),
      &:nth-child(90),
      &:nth-child(45),
      &:nth-child(135),
      &:nth-child(180) {
        display: block;
      }
      &:nth-child(180) {
        transform: translate(toRem(10), toRem(10)) !important;
      }
    }
  }

  .apexcharts-xaxis-label-90days {
    display: none;
    tspan:nth-child(1) {
      font-family: 'Work Sans' !important;
    }
    transform: translate(toRem(20), toRem(10)) !important;
    &:nth-child(1),
    &:nth-child(9),
    &:nth-child(17),
    &:nth-child(25),
    &:nth-child(33),
    &:nth-child(41),
    &:nth-child(50),
    &:nth-child(57),
    &:nth-child(65),
    &:nth-child(73),
    &:nth-child(81),
    &:nth-child(89) {
      display: block;
    }
    &:nth-child(89) {
      transform: translate(toRem(22), toRem(10)) !important;
    }
    @media screen and (max-width: 1000px) {
      &:nth-child(1),
      &:nth-child(9),
      &:nth-child(17),
      &:nth-child(25),
      &:nth-child(33),
      &:nth-child(41),
      &:nth-child(50),
      &:nth-child(57),
      &:nth-child(65),
      &:nth-child(73),
      &:nth-child(81),
      &:nth-child(89) {
        display: none;
      }
      &:nth-child(1),
      &:nth-child(23),
      &:nth-child(45),
      &:nth-child(66),
      &:nth-child(90) {
        display: block;
      }
      &:nth-child(90) {
        transform: translate(toRem(10), toRem(10)) !important;
      }
    }
  }

  .apexcharts-xaxis-label-30days {
    display: none;
    tspan:nth-child(1) {
      font-family: 'Work Sans' !important;
    }
    transform: translate(toRem(20), toRem(10)) !important;
    &:nth-child(1),
    &:nth-child(4),
    &:nth-child(7),
    &:nth-child(10),
    &:nth-child(13),
    &:nth-child(16),
    &:nth-child(19),
    &:nth-child(22),
    &:nth-child(25),
    &:nth-child(28)
    {
      display: block;
    }
    @media screen and (max-width: 1000px) {
      &:nth-child(1),
      &:nth-child(4),
      &:nth-child(7),
      &:nth-child(10),
      &:nth-child(13),
      &:nth-child(16),
      &:nth-child(19),
      &:nth-child(22),
      &:nth-child(25),
      &:nth-child(28)
      {
        display: none;
      }
      &:nth-child(1),
      &:nth-child(8),
      &:nth-child(15),
      &:nth-child(22),
      &:nth-child(30) {
        display: block;
      }
      &:nth-child(30) {
        transform: translate(toRem(10), toRem(10)) !important;
      }
    }
  }

  .apexcharts-xaxis-label-7days {
    tspan:nth-child(1) {
      font-family: 'Work Sans' !important;
    }
  }

  .apexcharts-xaxis-label-today {
    -webkit-transform: translateX(toRem(25)) !important;
    transform: translateX(toRem(25)) !important;

    &:nth-child(1) {
      -webkit-transform: translateX(toRem(25)) !important;
      transform: translateX(toRem(0)) !important;
    }
  }

  .apexcharts-xaxis-title {
    @media screen and (max-width:500px) {
      transform: translateY(-30px) translateX(-10px);
    }
  }

  .apexcharts-yaxistooltip {
    position: absolute !important;
    top: 0
  }

  .apexcharts-xaxistooltip {
    position: absolute !important;
    top: 0
  }
}