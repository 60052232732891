@import 'styles/scss/fontSizeByRem.scss';
.LoginWrapper {
  .content {
    .contentBody {
      .title {
        font-size: toRem(24);
        font-weight: 700;
        color: #161616;
        margin-top: toRem(32);
      }
      .form {
        width: toRem(448);
      }
      :global(.signInButton) {
        font-size: toRem(16);
        margin: toRem(32) 0;
      }
      .forgotPassText {
        margin-top: toRem(10);
        a {
          font-family: 'MonoSpec';
          font-weight: 500;
          font-size: toRem(12);
          line-height: 15px;
          letter-spacing: 0.01em;
          text-decoration-line: underline;
          text-transform: uppercase;
          color: #161616;
        }
      }
      :global(.googleSiginButton) {
        margin-top: toRem(32);
        font-family: 'MonoSpec';
        font-weight: 500;
        font-size: toRem(16);
        line-height: 20px;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        color: #161616;
        height: toRem(64);
        border: 1px solid #E6E6E6;
        img {
          width: toRem(20);
          height: toRem(20);
          margin-right: toRem(10);
        }
      }
      .line {
        background-color: #e6e6e6;
        height: 1px;
        position: relative;
        .orText {
          background-color: white;
          width: 50px;
          position: absolute;
          bottom: toRem(-10);
          left: calc(50% - 25px);
          font-family: 'MonoSpec';
          font-weight: 500;
          font-size: toRem(16);
          line-height: 20px;
          letter-spacing: 0.01em;
          text-transform: uppercase;
          color: #666666;
        }
      }
      .sentAOneTimeVerificationCode {
        font-family: 'Work Sans';
        font-weight: 400;
        font-size: toRem(16);
        line-height: 20px;
        color: #161616;
      }
    }
    @media screen and (max-width: 1023px) {
      .contentBody {
        align-items: baseline;
        margin-top: toRem(24);
        .title {
          margin-top: toRem(16);
        }
        .forgotPassText {
          margin-top: toRem(16);
        }
        :global(.signInButton) {
          margin: toRem(24) 0;
        }
        :global(.googleSiginButton) {
          margin-top: toRem(24);
        }
      }
    }
  }
}