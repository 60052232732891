@import 'styles/scss/variable.scss';

.TabsChart {
  :global(.MuiTabs-root) {
    :global(.MuiTabs-indicator) {
      display: none;
    }
    :global(.MuiTabs-flexContainer) {
      gap: 8px;
      :global(.MuiButtonBase-root) {
        border: 1px solid $BorderColor;
        border-radius: 4px;
        font-family: 'Work Sans';
        font-size: 12px;
        line-height: 19px;
        padding: 8px 8px;
        min-height: 20px;
        align-items: flex-start;
        -webkit-align-items: flex-start;
        -webkit-box-align: flex-start;
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
        -webkit-box-align:start;
        min-width: fit-content;
      }
      :global(.Mui-selected) {
        border: 1px solid $BorderActiveColor;
        font-weight: 600;
        color: $PrimaryText;
      }
    }
  }
}