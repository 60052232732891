.achievements-item {
    width: 160px;
    height: 160px;
    background-color: white;
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    margin-bottom: 20px;
    p {
        font-size: 14px;
        color: #161616;
        font-weight: 700;
    }
    @media screen and (max-width: 768px) {
        margin-right: 10px;
    }
}
