@import 'styles/scss/variable.scss';
.AddMachineCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 12px;
  padding: 12px 12px;
  height: 104px;
  cursor: pointer;

  .machineInfoBlock {
    display: flex;
    align-items: center;
    gap: toRem(28);
    .logo {
      img {
        width: toRem(80);
      }
    }
    .info1 {
      font-size: toRem(16);
      font-weight: 500;
      line-height: toRem(20);
      text-transform: uppercase;
      color: #808080;
      margin-bottom: toRem(6);
    }
    .info2 {
      font-size: toRem(20);
      font-weight: 500;
      line-height: toRem(20);
      text-transform: uppercase;
      color: #161616;
      margin-bottom: toRem(6);
    }
    .info3 {
      font-size: toRem(14);
      font-weight: 500;
      line-height: toRem(16);
      text-transform: uppercase;
      color: #808080;
    }
  }
}