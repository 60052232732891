@mixin remove-btn-style {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.cancel-membership-modal-component {
  width: 500px;
  border-radius: 5px;
  background-color: white;
  position: relative;
  padding: 48px;
  outline: none;
  .form-error {
    font-family: "Work Sans";
    font-size: 12px;
    color: #F5490F;
  }
  .select-box {
    cursor: pointer;
    &:hover {
      border: solid 1px #F5490F;
    }
    &__active {
      border: solid 1px #F5490F;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0 20px;
    padding: 32px;
  }

  .close-btn {
    @include remove-btn-style();
    position: absolute;
    top: 18px;
    right: 18px;
  }

  .buttons-group {
    padding: 24px 10%;
    display: grid;
    grid-template-rows: 1fr;
    row-gap: 12px;

    .btn {
      padding: 12px;
      // Please remove !important in the future
      border-radius: 8px !important;
    }
    .btn-default {
      border: 1px solid #000000;
    }
  }
}