.overViewPageWrapper {
    overflow-y: hidden;
    :global(.mui-grid-custom) {
        @media screen and (max-width: 600px) {
            margin-right: 0 !important;
            padding-right: 0 !important;
        }
    }
    .calendartGridItem {
        @media screen and (max-width: 600px) {
            width: 100vw !important;
            box-sizing: content-box;
        }
    }
}
