@import "styles/scss/variable.scss";

.achievements-all {
    margin-top: 40px;
    &-title {
        display: flex;
        align-items: center;
        &-image {
            width: 32px;
            height: 32px;
        }
        &-text {
            color: #161616;
            font-size: toRem(20);
            font-weight: 700;
            font-family: "GRIFTER";
            margin: 0;
            margin-left: 16px;
            margin-top: 5px;
        }
    }
    &-content {
        p {
            color: #161616;
            font-size: 16px;
        }
        .achievements-category {
            font-size: toRem(16);
            font-weight: 700;
            font-family: "GRIFTER";
            color: "#161616";
        }
        .achievements-list {
            display: flex;
            flex-wrap: wrap;
        }
    }
}
