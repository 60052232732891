@import 'styles/scss/variable.scss';
.MembershipCardMobile {
  background-color: white;
  .header {
    margin-bottom: toRem(24);
  }
  .membershipTab {
    margin-bottom: toRem(24);
  }
  .membershipTable {
    table {
      th {
        &:nth-child(4),&:nth-child(3), &:nth-child(5) {
          display: none;
        }
        &:nth-child(1) {
          width: 40px;
        }
        &:nth-child(4) {
          :global(.deleteFunc) {
            right: -5px;
          }
        }
      }
      tr {
        td {
          border-radius: none;
          &:nth-child(4), &:nth-child(5), &:nth-child(6) {
            display: none;
          }
          :global(.StatusWrapper) {
            position: relative;
            left: 15px;
          }
        }
        :global(.roleCell) {
          width: toRem(68);
        }
      }
      :global(.trHeader){
        th {
          padding-bottom: 0;
        }
      }
    }
  }
}