.register-beta-access {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #161616;
    cursor: pointer;
    &-left {
        &__title {
            font-family: "GRIFTER";
            font-size: 20px;
            font-weight: 700;
        }
        &__content {
            font-family: "Work Sans";
            font-size: 14px;
            font-weight: 400;
        }
    }
    &-right {
        display: flex;
        align-items: center;
        p {
            padding: 4px 12px;
            text-transform: uppercase;
            border-radius: 15px;
            font-weight: 400;
            color: #939393;
            margin-right: 18px;
        }
    }
}
@media screen and (max-width: 767px) {
    .register-beta-access {
        flex-direction: column;
        align-items: flex-start;
    }
}
