@import 'styles/scss/fontSizeByRem.scss';

.StatBoard {
  @media screen and (max-width: 1250px) {
    margin-top: toRem(16);
    .titleStatBoard {
      margin-bottom: toRem(16);
      .selectField {
        max-width: toRem(180);
      }
    }
    :global(.CardSimpleWrapper) {
      padding: toRem(16) toRem(16);
      :global(.parameter-container) {
        gap: toRem(16);
      }
    }
  }
  @media screen and (max-width: 1023px) {
    .titleStatBoard {
      :global(.titleCardItem) {
        max-width: fit-content;
      }
      .selectField {
        width: toRem(180);
      }
    }
  }
}