@import '../../../styles/scss/variable.scss';
.CompareChartWrapper {
  -webkit-overflow-scrolling: auto;
  :global(.CardSimpleWrapper) {
    padding-bottom: 0;
    padding-right: 10px;
    @media screen and (max-width: 600px) {
      padding: toRem(20) 0 0 toRem(5);
      :global(.titleCard) {
        margin-left: toRem(24);
        margin-bottom: toRem(16);
      }
      :global(.TabsChart) {
        width: toRem(366);
        margin-left: toRem(24);
      }
    }
  }
}