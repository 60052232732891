@import "../fontSizeByRem.scss";
.invite_memebership_popup {
    padding: 48px 24px !important;
    justify-content: flex-start;
    box-sizing: border-box !important;
    .swal2-header {
        flex-direction: row;
        font-size: 20px !important;
        font-family: GRIFTER !important;
        text-align: left !important;
        padding: 0;
        box-sizing: border-box !important;
    }
    .swal2-content {
        text-align: left;
        font-size: 16px;
        color: #161616;
        padding: 0;
        box-sizing: border-box !important;
        .emailField {
            width: calc(100% - 24px);
            padding: 18px 12px;
            border-radius: 8px;
            border: 1px solid #ccc;
            background-color: #f4f4f4;
            color: #939393;
            display: flex;
            align-items: center;
            margin-top: 24px;
            margin-bottom: 36px;
        }
    }
    .swal2-actions {
        padding: 0;
        button {
            width: 100%;
        }
        .swal2-styled:focus {
            box-shadow: none;
        }
    }
    @media screen and (max-width: 500px) {
        width: 340px !important;
        .swal2-content {
            padding: 24px 0 !important;
        }
        .swal2-header {
            .swal2-title {
                font-size: 20px !important;
                font-weight: 700 !important;
                line-height: 20px !important;
                letter-spacing: 1% !important;
            }
        }
    }
}
.confirm-membership-popup {
    padding: 48px 24px !important;
    box-sizing: border-box !important;
    width: 640px !important;
    .swal2-header {
        .swal2-title {
            margin-top: 30px;
            font-family: GRIFTER;
            font-size: 24px !important;
            font-weight: 700;
            line-height: 24.48px !important;
            letter-spacing: 0.01em !important;
            text-align: center;
            color: #161616;
            text-transform: uppercase !important;
        }
    }
    .swal2-html-container {
        font-family: Work Sans;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        color: #161616 !important;
    }
    .swal2-actions {
        padding: 0;
        button {
            width: 60%;
        }
        .swal2-cancel {
            background-color: unset !important;
            text-decoration: underline;
            &:hover {
                background-image: unset;
            }
        }
        .swal2-styled:focus {
            box-shadow: none;
        }
    }
    @media screen and (max-width: 500px) {
        width: 340px !important;
        .swal2-content {
            padding: 24px 0 !important;
        }
        .swal2-header {
            .swal2-title {
                font-size: 20px !important;
                font-weight: 700 !important;
                line-height: 20px !important;
                letter-spacing: 1% !important;
            }
        }
    }
}

.error-invite-member-popup {
    padding: 48px 24px !important;
    box-sizing: border-box !important;
    width: 640px !important;
    border-radius: 16px !important;
    .swal2-header {
        .swal2-title {
            margin-top: 30px !important;
            margin-bottom: 16px !important;
            font-family: GRIFTER !important;
            font-size: 24px !important;
            font-weight: 700 !important;
            line-height: 20px !important;
            letter-spacing: 0.01em !important;
            text-align: center !important;
            text-transform: uppercase !important;
        }
    }
    .swal2-html-container {
        font-family: Work Sans;
        font-size: 16px !important;
        font-weight: 400;
        line-height: 20px !important;
        text-align: center;
        color: #161616;
        opacity: 0.9;
    }
    .swal2-actions {
        margin: 0;
        margin-top: 56px;
        // padding: 0;
        // button {
        //     width: 56%;
        // }
    }
    @media screen and (max-width: 500px) {
        width: 340px !important;
        .swal2-content {
            padding: 24px 0 !important;
        }
        .swal2-header {
            .swal2-title {
                font-size: 20px !important;
                font-weight: 700 !important;
                line-height: 20px !important;
                letter-spacing: 1% !important;
            }
        }
    }
}
