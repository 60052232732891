// .contentAppWrapper {
//   height: 500px;
//   @media screen and (min-width: 1251px) {
//     // min-width: 1251px;
//     height: calc(100vh - 500px);
//   }
// }

.height-loading {
  height: 60vh;
}