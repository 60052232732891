@import 'styles/scss/variable.scss';
@import 'styles/scss/fontSizeByRem.scss';
.SelectFormFieldWrapper {
  width: 100%;
  :global(.MuiFormControl-root){
    :global(.MuiOutlinedInput-root) {
      :global(.MuiOutlinedInput-input) {
        padding: toRem(8) toRem(16);
        display: flex;
        align-items: center;
        // min-height: toRem(44);
        height: 46px !important;
        box-sizing: border-box;
        color: $PrimaryText;
        font-size: toRem(16);
        background-color: transparent;
        font-weight: 500;
        text-align: left;
      }
      fieldset {
        border: 1px solid $BorderColor;
        border-radius: toRem(50);
      }
      &.Mui-focused {
        fieldset {
          border: 1px solid $PrimaryText;
        }
      }
    }
    :global(.MuiSelect-icon) {
      right: toRem(10);
      color: $color-gray;
    }
  }
  .menuSelectItem {
    font-size: toRem(32);
  }
}