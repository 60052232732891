@import "styles/scss/variable.scss";
@import "styles/scss/fontSizeByRem.scss";

@mixin flex-func($direction, $justify, $align) {
  display: flex;
  flex-direction: $direction;
  align-items: $align;
  justify-content: $justify;
}

.item-content-container {
  width: 100%;
  display: flex;
  position: relative;
  background: $GrayColor2;
  box-shadow: 0px 2px 14px rgba(149, 157, 165, 0.1);
  border-radius: 0.57rem ;
  padding: toRem(12) toRem(12);
  &.__active {
    background-color: $GrayColor1;
    .metric-line {
      border-color: $GrayColor !important;
    } 
  }
  .marker {
    position: absolute;
    right: 0;
    top: 0;
    // height: 20px;
    // background-color: #e03100;
    // transform: rotate(45deg);
    color: $SecondaryText;
    overflow: hidden;
    z-index: 1;
  }
  .content-container {
    flex: 1;
    display: grid;
    row-gap: 0.571rem;
    .date {
      display: flex;
      font-size: 14px;
      color: $PrimaryText;
      font-family: 'MonoSpec';
      @media screen and (max-width: 414px) {
        font-size: 12px;
      }
      .month {
        width: fit-content;
      }
      .day {
        width: fit-content;
      }
    }
    .wrapper-name {
      position: relative;
      margin-top: toRem(-5);
      .name {
        justify-content: flex-start;
        text-align: left;
        font-family: 'GRIFTER';
        font-size: toRem(18);
        line-height: toRem(19);
        text-overflow: ellipsis;

        span {
          color: #e03100;
          font-size: 10px;
        }
      }
      @media screen and (max-width: 414px) {
        .name {
          font-size: 16px;
        }
      }
    }

    .rank {
      @include flex-func(row, center, center);
      background-color: $color-gray-light;

      font-family: "MonoSpec";
      font-size: 18px;
    }

    .active-box {
      background-color: black;
      color: white;
    }

    .metrics {
      display: grid;
      grid-template-columns: 50% 50%;
      row-gap: toRem(5);
      .metrics-item-container {
        text-align: left;
        color: $BlackText;
        font-family: "MonoSpec";
        .metric-label {
          font-family: 'Work Sans';
          opacity: 0.6;
          font-size: toRem(12);
        }
        .metric-number {
          font-size: 18px;
          line-height: 22px;
        }

        @media screen and (max-width: $screen-sm-workout-max){
          .metric-label {
            font-size: 10px;
          }
          .metric-number {
            font-size: 16px;
            line-height: 22px;
          }
        }

        @media (max-width: 375px) {
          .metric-label {
            font-size: 8px;
          }
          .metric-number {
            font-size: 16px;
          }
        }
      }
      @media screen and (max-width: $screen-sm-workout-max){
        grid-template-columns: 25% 25% 25% 25%;
      }
    }
    .metric-line {
      border: 1px solid $GrayColor3;
      height: 0;
    }
  }

  .globar {
    width: 3px;
    min-height: 100%;
  }
  .globar-unactive {
    background: transparent;
  }
  .globar-active {
    background: #e03100;
    box-shadow: 0px 0px 99.4231px 20px rgba(234, 86, 39, 0.3);
  }
}
