.AppChip {
  .appChipContent {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 4px 16px;
    width: fit-content;

    // background: rgba(255, 65, 6, 0.1);
    /* Primary/500 */
    border: 1px solid;
    border-radius: 100px;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 16px;
  }
}