.cancellation-offer {
    .hand-gift {
        text-align: center;
        margin-bottom: 40px;
        img {
            width: 200px;
            height: 144px;
        }
    }
    .high-light-text {
        background-color: #ff4106;
        margin-right: 5px;
        display: inline-block;
        font-family: GRIFTER;
        font-size: 32px;
        font-weight: 700;
        height: 35.44px;
        line-height: 43.44px;
        letter-spacing: 0.05em;
        text-align: center;
        color: #fff;
        padding: 0px 3px;
    }
}

@media screen and (max-width: 768px) {
    .cancellation-offer {
        margin-top: 24px;
        margin-bottom: 24px;

        padding-left: 12px;
        padding-right: 12px;
        .high-light-text {
            display: inline-block;
            height: 31px;
            line-height: 39px;
            font-family: GRIFTER;
            font-size: 32px;
            font-weight: 700;
            letter-spacing: 0.05em;
            text-align: center;
            padding: 0px;
        }
        .hand-gift {
            text-align: center;
            margin-bottom: 32px;
            img {
                width: 180px;
                height: 130px;
            }
        }
    }
}
