@import '../../../styles/scss/fontSizeByRem.scss';

.LeftContentWrapper {
  display: block;
  max-width: toRem(880);
  overflow-x: hidden;
  min-height: 100vh;
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 0;
  .title {
    text-align: center;
    margin-top: toRem(96);
    .mainTitle {
      font-family: 'GRIFTER';
      font-weight: 700;
      font-size: toRem(82);
      letter-spacing: 0.36em;
      text-transform: uppercase;
      color: #161616;
      margin-top: 0;
      margin-bottom: 0;
    }
    .subTitle {
      font-family: 'GRIFTER';
      font-weight: 700;
      font-size: toRem(32);
      letter-spacing: 0.12em;
      text-transform: uppercase;
      color: #808080;
      margin-top: toRem(6);
      position: relative;
      right: toRem(11);
    }
  }
  .mainFeatures {
    text-align: center;
    // margin-top: toRem(40);
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    .desktopLoginBg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .mobileLoginBg {
      width: 100%;
      height: 100%;
    }
  }
  @media screen and (max-width: 1023px) {
    min-height: toRem(276);
    .title {
      display: none;
    }
    .mainFeatures {
      margin-top: 0;
    }
  }
}